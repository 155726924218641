import ElementPlus, { ID_INJECTION_KEY } from "element-plus";
import "element-plus/dist/index.css";
import { defineNuxtPlugin } from "nuxt/app";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp
    .provide(ID_INJECTION_KEY, {
      prefix: Math.floor(Math.random() * 1000),
      current: 0,
    })
    .use(ElementPlus);
});
