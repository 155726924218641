import { defineStore } from "pinia";
import { useOrgStore } from "./org";
import User from "~/types/UserType";

type State = {
  user: User | null;
};

export const useUserStore = defineStore("user", {
  state: (): State => ({
    user: null,
  }),
  getters: {
    getUser(state) {
      return state.user;
    },
    getMentionsMonitorSort(state) {
      return (
        state?.user?.preferences?.article_sort?.monitor ?? "relevance:desc"
      );
    },
    allowSocialAccess(state) {
      const orgStore = useOrgStore();

      return orgStore.hasSocialTracking && state?.user?.social_listening_access;
    },
  },
  actions: {
    setUser(user: User) {
      this.user = user;
    },
    setMentionsMonitorSort(newSortPreference: string) {
      if (this.user) {
        this.user.preferences.article_sort.monitor = newSortPreference;
      }
    },
  },
});
