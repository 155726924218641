import validate from "/unified-client/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/unified-client/middleware/auth.global.ts";
import full_45story_45global from "/unified-client/middleware/full-story.global.ts";
import intercom_45global from "/unified-client/middleware/intercom.global.ts";
import navigation_45global from "/unified-client/middleware/navigation.global.ts";
import manifest_45route_45rule from "/unified-client/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  full_45story_45global,
  intercom_45global,
  navigation_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}