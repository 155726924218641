const IANA_TIME_ZONES = [
  {
    groupName: "iana_time_zones.group_name.us_common",
    options: [
      {
        value: "America/Puerto_Rico",
        name: "iana_time_zones.name.america_puerto_rico_us_common",
      },
      {
        value: "America/New_York",
        name: "iana_time_zones.name.america_new_york_us_common",
      },
      {
        value: "America/Chicago",
        name: "iana_time_zones.name.america_chicago_us_common",
      },
      {
        value: "America/Denver",
        name: "iana_time_zones.name.america_denver_us_common",
      },
      {
        value: "America/Phoenix",
        name: "iana_time_zones.name.america_phoenix_us_common",
      },
      {
        value: "America/Los_Angeles",
        name: "iana_time_zones.name.america_los_angeles_us_common",
      },
      {
        value: "America/Anchorage",
        name: "iana_time_zones.name.america_anchorage_us_common",
      },
      {
        value: "Pacific/Honolulu",
        name: "iana_time_zones.name.pacific_honolulu",
      },
    ],
  },
  {
    groupName: "iana_time_zones.group_name.america",
    options: [
      { value: "America/Adak", name: "iana_time_zones.name.america_adak" },
      {
        value: "America/Anchorage",
        name: "iana_time_zones.name.america_anchorage",
      },
      {
        value: "America/Anguilla",
        name: "iana_time_zones.name.america_anguilla",
      },
      {
        value: "America/Antigua",
        name: "iana_time_zones.name.america_antigua",
      },
      {
        value: "America/Araguaina",
        name: "iana_time_zones.name.america_araguaina",
      },
      {
        value: "America/Argentina/Buenos_Aires",
        name: "iana_time_zones.name.america_argentina_buenos_aires",
      },
      {
        value: "America/Argentina/Catamarca",
        name: "iana_time_zones.name.america_argentina_catamarca",
      },
      {
        value: "America/Argentina/ComodRivadavia",
        name: "iana_time_zones.name.america_argentina_comodrivadavia",
      },
      {
        value: "America/Argentina/Cordoba",
        name: "iana_time_zones.name.america_argentina_cordoba",
      },
      {
        value: "America/Argentina/Jujuy",
        name: "iana_time_zones.name.america_argentina_jujuy",
      },
      {
        value: "America/Argentina/La_Rioja",
        name: "iana_time_zones.name.america_argentina_la_rioja",
      },
      {
        value: "America/Argentina/Mendoza",
        name: "iana_time_zones.name.america_argentina_mendoza",
      },
      {
        value: "America/Argentina/Rio_Gallegos",
        name: "iana_time_zones.name.america_argentina_rio_gallegos",
      },
      {
        value: "America/Argentina/Salta",
        name: "iana_time_zones.name.america_argentina_salta",
      },
      {
        value: "America/Argentina/San_Juan",
        name: "iana_time_zones.name.america_argentina_san_juan",
      },
      {
        value: "America/Argentina/San_Luis",
        name: "iana_time_zones.name.america_argentina_san_luis",
      },
      {
        value: "America/Argentina/Tucuman",
        name: "iana_time_zones.name.america_argentina_tucuman",
      },
      {
        value: "America/Argentina/Ushuaia",
        name: "iana_time_zones.name.america_argentina_ushuaia",
      },
      { value: "America/Aruba", name: "iana_time_zones.name.america_aruba" },
      {
        value: "America/Asuncion",
        name: "iana_time_zones.name.america_asuncion",
      },
      {
        value: "America/Atikokan",
        name: "iana_time_zones.name.america_atikokan",
      },
      { value: "America/Atka", name: "iana_time_zones.name.america_atka" },
      { value: "America/Bahia", name: "iana_time_zones.name.america_bahia" },
      {
        value: "America/Barbados",
        name: "iana_time_zones.name.america_barbados",
      },
      { value: "America/Belem", name: "iana_time_zones.name.america_belem" },
      { value: "America/Belize", name: "iana_time_zones.name.america_belize" },
      {
        value: "America/Blanc-Sablon",
        name: "iana_time_zones.name.america_blanc-Sablon",
      },
      {
        value: "America/Boa_Vista",
        name: "iana_time_zones.name.america_boa_vista",
      },
      { value: "America/Bogota", name: "iana_time_zones.name.america_bogota" },
      { value: "America/Boise", name: "iana_time_zones.name.america_boise" },
      {
        value: "America/Buenos_Aires",
        name: "iana_time_zones.name.america_buenos_aires",
      },
      {
        value: "America/Cambridge_Bay",
        name: "iana_time_zones.name.america_cambridge_bay",
      },
      {
        value: "America/Campo_Grande",
        name: "iana_time_zones.name.america_campo_grande",
      },
      { value: "America/Cancun", name: "iana_time_zones.name.america_cancun" },
      {
        value: "America/Caracas",
        name: "iana_time_zones.name.america_caracas",
      },
      {
        value: "America/Catamarca",
        name: "iana_time_zones.name.america_catamarca",
      },
      {
        value: "America/Cayenne",
        name: "iana_time_zones.name.america_cayenne",
      },
      { value: "America/Cayman", name: "iana_time_zones.name.america_cayman" },
      {
        value: "America/Chicago",
        name: "iana_time_zones.name.america_chicago",
      },
      {
        value: "America/Chihuahua",
        name: "iana_time_zones.name.america_chihuahua",
      },
      {
        value: "America/Coral_Harbour",
        name: "iana_time_zones.name.america_coral_harbour",
      },
      {
        value: "America/Cordoba",
        name: "iana_time_zones.name.america_cordoba",
      },
      {
        value: "America/Costa_Rica",
        name: "iana_time_zones.name.america_costa_rica",
      },
      { value: "America/Cuiaba", name: "iana_time_zones.name.america_cuiaba" },
      {
        value: "America/Curacao",
        name: "iana_time_zones.name.america_curacao",
      },
      {
        value: "America/Danmarkshavn",
        name: "iana_time_zones.name.america_danmarkshavn",
      },
      { value: "America/Dawson", name: "iana_time_zones.name.america_dawson" },
      {
        value: "America/Dawson_Creek",
        name: "iana_time_zones.name.america_dawson_creek",
      },
      { value: "America/Denver", name: "iana_time_zones.name.america_denver" },
      {
        value: "America/Detroit",
        name: "iana_time_zones.name.america_detroit",
      },
      {
        value: "America/Dominica",
        name: "iana_time_zones.name.america_dominica",
      },
      {
        value: "America/Edmonton",
        name: "iana_time_zones.name.america_edmonton",
      },
      {
        value: "America/Eirunepe",
        name: "iana_time_zones.name.america_eirunepe",
      },
      {
        value: "America/El_Salvador",
        name: "iana_time_zones.name.america_el_salvador",
      },
      {
        value: "America/Ensenada",
        name: "iana_time_zones.name.america_ensenada",
      },
      {
        value: "America/Fortaleza",
        name: "iana_time_zones.name.america_fortaleza",
      },
      {
        value: "America/Fort_Wayne",
        name: "iana_time_zones.name.america_fort_wayne",
      },
      {
        value: "America/Glace_Bay",
        name: "iana_time_zones.name.america_glace_bay",
      },
      {
        value: "America/Godthab",
        name: "iana_time_zones.name.america_godthab",
      },
      {
        value: "America/Goose_Bay",
        name: "iana_time_zones.name.america_goose_bay",
      },
      {
        value: "America/Grand_Turk",
        name: "iana_time_zones.name.america_grand_turk",
      },
      {
        value: "America/Grenada",
        name: "iana_time_zones.name.america_grenada",
      },
      {
        value: "America/Guadeloupe",
        name: "iana_time_zones.name.america_guadeloupe",
      },
      {
        value: "America/Guatemala",
        name: "iana_time_zones.name.america_guatemala",
      },
      {
        value: "America/Guayaquil",
        name: "iana_time_zones.name.america_guayaquil",
      },
      { value: "America/Guyana", name: "iana_time_zones.name.america_guyana" },
      {
        value: "America/Halifax",
        name: "iana_time_zones.name.america_halifax",
      },
      { value: "America/Havana", name: "iana_time_zones.name.america_havana" },
      {
        value: "America/Hermosillo",
        name: "iana_time_zones.name.america_hermosillo",
      },
      {
        value: "America/Indiana/Indianapolis",
        name: "iana_time_zones.name.america_indiana_indianapolis",
      },
      {
        value: "America/Indiana/Knox",
        name: "iana_time_zones.name.america_indiana_knox",
      },
      {
        value: "America/Indiana/Marengo",
        name: "iana_time_zones.name.america_indiana_marengo",
      },
      {
        value: "America/Indiana/Petersburg",
        name: "iana_time_zones.name.america_indiana_petersburg",
      },
      {
        value: "America/Indiana/Tell_City",
        name: "iana_time_zones.name.america_indiana_tell_city",
      },
      {
        value: "America/Indiana/Vevay",
        name: "iana_time_zones.name.america_indiana_vevay",
      },
      {
        value: "America/Indiana/Vincennes",
        name: "iana_time_zones.name.america_indiana_vincennes",
      },
      {
        value: "America/Indiana/Winamac",
        name: "iana_time_zones.name.america_indiana_winamac",
      },
      {
        value: "America/Indianapolis",
        name: "iana_time_zones.name.america_indianapolis",
      },
      { value: "America/Inuvik", name: "iana_time_zones.name.america_inuvik" },
      {
        value: "America/Iqaluit",
        name: "iana_time_zones.name.america_iqaluit",
      },
      {
        value: "America/Jamaica",
        name: "iana_time_zones.name.america_jamaica",
      },
      { value: "America/Jujuy", name: "iana_time_zones.name.america_jujuy" },
      { value: "America/Juneau", name: "iana_time_zones.name.america_juneau" },
      {
        value: "America/Kentucky/Louisville",
        name: "iana_time_zones.name.america_kentucky_louisville",
      },
      {
        value: "America/Kentucky/Monticello",
        name: "iana_time_zones.name.america_kentucky_monticello",
      },
      {
        value: "America/Knox_IN",
        name: "iana_time_zones.name.america_knox_in",
      },
      { value: "America/La_Paz", name: "iana_time_zones.name.america_la_paz" },
      { value: "America/Lima", name: "iana_time_zones.name.america_lima" },
      {
        value: "America/Los_Angeles",
        name: "iana_time_zones.name.america_los_angeles",
      },
      {
        value: "America/Louisville",
        name: "iana_time_zones.name.america_louisville",
      },
      { value: "America/Maceio", name: "iana_time_zones.name.america_maceio" },
      {
        value: "America/Managua",
        name: "iana_time_zones.name.america_managua",
      },
      { value: "America/Manaus", name: "iana_time_zones.name.america_manaus" },
      { value: "America/Marigot", name: "iana_time_zones.name.america_marig" },
      {
        value: "America/Martinique",
        name: "iana_time_zones.name.america_martinique",
      },
      {
        value: "America/Matamoros",
        name: "iana_time_zones.name.america_matamoros",
      },
      {
        value: "America/Mazatlan",
        name: "iana_time_zones.name.america_mazatlan",
      },
      {
        value: "America/Mendoza",
        name: "iana_time_zones.name.america_mendoza",
      },
      {
        value: "America/Menominee",
        name: "iana_time_zones.name.america_menominee",
      },
      { value: "America/Merida", name: "iana_time_zones.name.america_merida" },
      {
        value: "America/Mexico_City",
        name: "iana_time_zones.name.america_mexico_city",
      },
      {
        value: "America/Miquelon",
        name: "iana_time_zones.name.america_miquelon",
      },
      {
        value: "America/Moncton",
        name: "iana_time_zones.name.america_moncton",
      },
      {
        value: "America/Monterrey",
        name: "iana_time_zones.name.america_monterrey",
      },
      {
        value: "America/Montevideo",
        name: "iana_time_zones.name.america_montevideo",
      },
      {
        value: "America/Montreal",
        name: "iana_time_zones.name.america_montreal",
      },
      {
        value: "America/Montserrat",
        name: "iana_time_zones.name.america_montserrat",
      },
      { value: "America/Nassau", name: "iana_time_zones.name.america_nassau" },
      {
        value: "America/New_York",
        name: "iana_time_zones.name.america_new_york",
      },
      {
        value: "America/Nipigon",
        name: "iana_time_zones.name.america_nipigon",
      },
      { value: "America/Nome", name: "iana_time_zones.name.america_nome" },
      {
        value: "America/Noronha",
        name: "iana_time_zones.name.america_noronha",
      },
      {
        value: "America/North_Dakota/Center",
        name: "iana_time_zones.name.america_north_dakota_center",
      },
      {
        value: "America/North_Dakota/New_Salem",
        name: "iana_time_zones.name.america_north_dakota_new_salem",
      },
      {
        value: "America/Ojinaga",
        name: "iana_time_zones.name.america_ojinaga",
      },
      { value: "America/Panama", name: "iana_time_zones.name.america_panama" },
      {
        value: "America/Pangnirtung",
        name: "iana_time_zones.name.america_pangnirtung",
      },
      {
        value: "America/Paramaribo",
        name: "iana_time_zones.name.america_paramaribo",
      },
      {
        value: "America/Phoenix",
        name: "iana_time_zones.name.america_phoenix",
      },
      {
        value: "America/Port-au-Prince",
        name: "iana_time_zones.name.america_port",
      },
      {
        value: "America/Porto_Acre",
        name: "iana_time_zones.name.america_porto_acre",
      },
      {
        value: "America/Port_of_Spain",
        name: "iana_time_zones.name.america_port_of_spain",
      },
      {
        value: "America/Porto_Velho",
        name: "iana_time_zones.name.america_porto_velho",
      },
      {
        value: "America/Puerto_Rico",
        name: "iana_time_zones.name.america_puerto_rico",
      },
      {
        value: "America/Rainy_River",
        name: "iana_time_zones.name.america_rainy_river",
      },
      {
        value: "America/Rankin_Inlet",
        name: "iana_time_zones.name.america_rankin_inlet",
      },
      { value: "America/Recife", name: "iana_time_zones.name.america_recife" },
      { value: "America/Regina", name: "iana_time_zones.name.america_regina" },
      {
        value: "America/Resolute",
        name: "iana_time_zones.name.america_resolute",
      },
      {
        value: "America/Rio_Branco",
        name: "iana_time_zones.name.america_rio_branco",
      },
      {
        value: "America/Rosario",
        name: "iana_time_zones.name.america_rosario",
      },
      {
        value: "America/Santa_Isabel",
        name: "iana_time_zones.name.america_santa_isabel",
      },
      {
        value: "America/Santarem",
        name: "iana_time_zones.name.america_santarem",
      },
      {
        value: "America/Santiago",
        name: "iana_time_zones.name.america_santiago",
      },
      {
        value: "America/Santo_Domingo",
        name: "iana_time_zones.name.america_santo_domingo",
      },
      {
        value: "America/Sao_Paulo",
        name: "iana_time_zones.name.america_sao_paulo",
      },
      {
        value: "America/Scoresbysund",
        name: "iana_time_zones.name.america_scoresbysund",
      },
      {
        value: "America/Shiprock",
        name: "iana_time_zones.name.america_shiprock",
      },
      {
        value: "America/St_Barthelemy",
        name: "iana_time_zones.name.america_st_barthelemy",
      },
      {
        value: "America/St_Johns",
        name: "iana_time_zones.name.america_st_johns",
      },
      {
        value: "America/St_Kitts",
        name: "iana_time_zones.name.america_st_kitts",
      },
      {
        value: "America/St_Lucia",
        name: "iana_time_zones.name.america_st_lucia",
      },
      {
        value: "America/St_Thomas",
        name: "iana_time_zones.name.america_st_thomas",
      },
      {
        value: "America/St_Vincent",
        name: "iana_time_zones.name.america_st_vincent",
      },
      {
        value: "America/Swift_Current",
        name: "iana_time_zones.name.america_swift_current",
      },
      {
        value: "America/Tegucigalpa",
        name: "iana_time_zones.name.america_tegucigalpa",
      },
      { value: "America/Thule", name: "iana_time_zones.name.america_thule" },
      {
        value: "America/Thunder_Bay",
        name: "iana_time_zones.name.america_thunder_bay",
      },
      {
        value: "America/Tijuana",
        name: "iana_time_zones.name.america_tijuana",
      },
      {
        value: "America/Toronto",
        name: "iana_time_zones.name.america_toronto",
      },
      {
        value: "America/Tortola",
        name: "iana_time_zones.name.america_tortola",
      },
      {
        value: "America/Vancouver",
        name: "iana_time_zones.name.america_vancouver",
      },
      { value: "America/Virgin", name: "iana_time_zones.name.america_virgin" },
      {
        value: "America/Whitehorse",
        name: "iana_time_zones.name.america_whitehorse",
      },
      {
        value: "America/Winnipeg",
        name: "iana_time_zones.name.america_winnipeg",
      },
      {
        value: "America/Yakutat",
        name: "iana_time_zones.name.america_yakutat",
      },
      {
        value: "America/Yellowknife",
        name: "iana_time_zones.name.america_yellowknife",
      },
    ],
  },
  {
    groupName: "iana_time_zones.group_name.europe",
    options: [
      { value: "Europe/Amsterdam", name: "iana_time_zones.name.amsterdam" },
      { value: "Europe/Andorra", name: "iana_time_zones.name.andorra" },
      { value: "Europe/Athens", name: "iana_time_zones.name.athens" },
      { value: "Europe/Belfast", name: "iana_time_zones.name.belfast" },
      { value: "Europe/Belgrade", name: "iana_time_zones.name.belgrade" },
      { value: "Europe/Berlin", name: "iana_time_zones.name.berlin" },
      { value: "Europe/Bratislava", name: "iana_time_zones.name.bratislava" },
      { value: "Europe/Brussels", name: "iana_time_zones.name.brussels" },
      { value: "Europe/Bucharest", name: "iana_time_zones.name.bucharest" },
      { value: "Europe/Budapest", name: "iana_time_zones.name.budapest" },
      { value: "Europe/Chisinau", name: "iana_time_zones.name.chisinau" },
      { value: "Europe/Copenhagen", name: "iana_time_zones.name.copenhagen" },
      { value: "Europe/Dublin", name: "iana_time_zones.name.dublin" },
      { value: "Europe/Gibraltar", name: "iana_time_zones.name.gibraltar" },
      { value: "Europe/Guernsey", name: "iana_time_zones.name.guernsey" },
      { value: "Europe/Helsinki", name: "iana_time_zones.name.helsinki" },
      { value: "Europe/Isle_of_Man", name: "iana_time_zones.name.isle_of_man" },
      { value: "Europe/Istanbul", name: "iana_time_zones.name.istanbul" },
      { value: "Europe/Jersey", name: "iana_time_zones.name.jersey" },
      { value: "Europe/Kaliningrad", name: "iana_time_zones.name.kaliningrad" },
      { value: "Europe/Kiev", name: "iana_time_zones.name.kiev" },
      { value: "Europe/Lisbon", name: "iana_time_zones.name.lisbon" },
      { value: "Europe/Ljubljana", name: "iana_time_zones.name.ljubljana" },
      { value: "Europe/London", name: "iana_time_zones.name.london" },
      { value: "Europe/Luxembourg", name: "iana_time_zones.name.luxembourg" },
      { value: "Europe/Madrid", name: "iana_time_zones.name.madrid" },
      { value: "Europe/Malta", name: "iana_time_zones.name.malta" },
      { value: "Europe/Mariehamn", name: "iana_time_zones.name.mariehamn" },
      { value: "Europe/Minsk", name: "iana_time_zones.name.minsk" },
      { value: "Europe/Monaco", name: "iana_time_zones.name.monaco" },
      { value: "Europe/Moscow", name: "iana_time_zones.name.moscow" },
      { value: "Europe/Nicosia", name: "iana_time_zones.name.nicosia" },
      { value: "Europe/Oslo", name: "iana_time_zones.name.oslo" },
      { value: "Europe/Paris", name: "iana_time_zones.name.paris" },
      { value: "Europe/Podgorica", name: "iana_time_zones.name.podgorica" },
      { value: "Europe/Prague", name: "iana_time_zones.name.prague" },
      { value: "Europe/Riga", name: "iana_time_zones.name.riga" },
      { value: "Europe/Rome", name: "iana_time_zones.name.rome" },
      { value: "Europe/Samara", name: "iana_time_zones.name.samara" },
      { value: "Europe/San_Marino", name: "iana_time_zones.name.san_marino" },
      { value: "Europe/Sarajevo", name: "iana_time_zones.name.sarajevo" },
      { value: "Europe/Simferopol", name: "iana_time_zones.name.simferopol" },
      { value: "Europe/Skopje", name: "iana_time_zones.name.skopje" },
      { value: "Europe/Sofia", name: "iana_time_zones.name.sofia" },
      { value: "Europe/Stockholm", name: "iana_time_zones.name.stockholm" },
      { value: "Europe/Tallinn", name: "iana_time_zones.name.tallinn" },
      { value: "Europe/Tirane", name: "iana_time_zones.name.tirane" },
      { value: "Europe/Tiraspol", name: "iana_time_zones.name.tiraspol" },
      { value: "Europe/Uzhgorod", name: "iana_time_zones.name.uzhgorod" },
      { value: "Europe/Vaduz", name: "iana_time_zones.name.vaduz" },
      { value: "Europe/Vatican", name: "iana_time_zones.name.vatican" },
      { value: "Europe/Vienna", name: "iana_time_zones.name.vienna" },
      { value: "Europe/Vilnius", name: "iana_time_zones.name.vilnius" },
      { value: "Europe/Volgograd", name: "iana_time_zones.name.volgograd" },
      { value: "Europe/Warsaw", name: "iana_time_zones.name.warsaw" },
      { value: "Europe/Zagreb", name: "iana_time_zones.name.zagreb" },
      { value: "Europe/Zaporozhye", name: "iana_time_zones.name.zaporozhye" },
      { value: "Europe/Zurich", name: "iana_time_zones.name.zurich" },
    ],
  },
  {
    groupName: "iana_time_zones.group_name.asia",
    options: [
      { value: "Asia/Aden", name: "iana_time_zones.name.aden" },
      { value: "Asia/Almaty", name: "iana_time_zones.name.almaty" },
      { value: "Asia/Amman", name: "iana_time_zones.name.amman" },
      { value: "Asia/Anadyr", name: "iana_time_zones.name.anadyr" },
      { value: "Asia/Aqtau", name: "iana_time_zones.name.aqtau" },
      { value: "Asia/Aqtobe", name: "iana_time_zones.name.aqtobe" },
      { value: "Asia/Ashgabat", name: "iana_time_zones.name.ashgabat" },
      { value: "Asia/Ashkhabad", name: "iana_time_zones.name.ashkhabad" },
      { value: "Asia/Baghdad", name: "iana_time_zones.name.baghdad" },
      { value: "Asia/Bahrain", name: "iana_time_zones.name.bahrain" },
      { value: "Asia/Baku", name: "iana_time_zones.name.baku" },
      { value: "Asia/Bangkok", name: "iana_time_zones.name.bangkok" },
      { value: "Asia/Beirut", name: "iana_time_zones.name.beirut" },
      { value: "Asia/Bishkek", name: "iana_time_zones.name.bishkek" },
      { value: "Asia/Brunei", name: "iana_time_zones.name.brunei" },
      { value: "Asia/Calcutta", name: "iana_time_zones.name.calcutta" },
      { value: "Asia/Choibalsan", name: "iana_time_zones.name.choibalsan" },
      { value: "Asia/Chongqing", name: "iana_time_zones.name.chongqing" },
      { value: "Asia/Chungking", name: "iana_time_zones.name.chungking" },
      { value: "Asia/Colombo", name: "iana_time_zones.name.colombo" },
      { value: "Asia/Dacca", name: "iana_time_zones.name.dacca" },
      { value: "Asia/Damascus", name: "iana_time_zones.name.damascus" },
      { value: "Asia/Dhaka", name: "iana_time_zones.name.dhaka" },
      { value: "Asia/Dili", name: "iana_time_zones.name.dili" },
      { value: "Asia/Dubai", name: "iana_time_zones.name.dubai" },
      { value: "Asia/Dushanbe", name: "iana_time_zones.name.dushanbe" },
      { value: "Asia/Gaza", name: "iana_time_zones.name.gaza" },
      { value: "Asia/Harbin", name: "iana_time_zones.name.harbin" },
      { value: "Asia/Ho_Chi_Minh", name: "iana_time_zones.name.ho_chi_minh" },
      { value: "Asia/Hong_Kong", name: "iana_time_zones.name.hong_kong" },
      { value: "Asia/Hovd", name: "iana_time_zones.name.hovd" },
      { value: "Asia/Irkutsk", name: "iana_time_zones.name.irkutsk" },
      { value: "Asia/Istanbul", name: "iana_time_zones.name.istanbul_asia" },
      { value: "Asia/Jakarta", name: "iana_time_zones.name.jakarta" },
      { value: "Asia/Jayapura", name: "iana_time_zones.name.jayapura" },
      { value: "Asia/Jerusalem", name: "iana_time_zones.name.jerusalem" },
      { value: "Asia/Kabul", name: "iana_time_zones.name.kabul" },
      { value: "Asia/Kamchatka", name: "iana_time_zones.name.kamchatka" },
      { value: "Asia/Karachi", name: "iana_time_zones.name.karachi" },
      { value: "Asia/Kashgar", name: "iana_time_zones.name.kashgar" },
      { value: "Asia/Kathmandu", name: "iana_time_zones.name.kathmandu" },
      { value: "Asia/Katmandu", name: "iana_time_zones.name.katmandu" },
      { value: "Asia/Kolkata", name: "iana_time_zones.name.kolkata" },
      { value: "Asia/Krasnoyarsk", name: "iana_time_zones.name.krasnoyarsk" },
      { value: "Asia/Kuala_Lumpur", name: "iana_time_zones.name.kuala_lumpur" },
      { value: "Asia/Kuching", name: "iana_time_zones.name.kuching" },
      { value: "Asia/Kuwait", name: "iana_time_zones.name.kuwait" },
      { value: "Asia/Macao", name: "iana_time_zones.name.macao" },
      { value: "Asia/Macau", name: "iana_time_zones.name.macau" },
      { value: "Asia/Magadan", name: "iana_time_zones.name.magadan" },
      { value: "Asia/Makassar", name: "iana_time_zones.name.makassar" },
      { value: "Asia/Manila", name: "iana_time_zones.name.manila" },
      { value: "Asia/Muscat", name: "iana_time_zones.name.muscat" },
      { value: "Asia/Nicosia", name: "iana_time_zones.name.nicosia_asia" },
      { value: "Asia/Novokuznetsk", name: "iana_time_zones.name.novokuznetsk" },
      { value: "Asia/Novosibirsk", name: "iana_time_zones.name.novosibirsk" },
      { value: "Asia/Omsk", name: "iana_time_zones.name.omsk" },
      { value: "Asia/Oral", name: "iana_time_zones.name.oral" },
      { value: "Asia/Phnom_Penh", name: "iana_time_zones.name.phnom_penh" },
      { value: "Asia/Pontianak", name: "iana_time_zones.name.pontianak" },
      { value: "Asia/Pyongyang", name: "iana_time_zones.name.pyongyang" },
      { value: "Asia/Qatar", name: "iana_time_zones.name.qatar" },
      { value: "Asia/Qyzylorda", name: "iana_time_zones.name.qyzylorda" },
      { value: "Asia/Rangoon", name: "iana_time_zones.name.rangoon" },
      { value: "Asia/Riyadh", name: "iana_time_zones.name.riyadh" },
      { value: "Asia/Saigon", name: "iana_time_zones.name.saigon" },
      { value: "Asia/Sakhalin", name: "iana_time_zones.name.sakhalin" },
      { value: "Asia/Samarkand", name: "iana_time_zones.name.samarkand" },
      { value: "Asia/Seoul", name: "iana_time_zones.name.seoul" },
      { value: "Asia/Shanghai", name: "iana_time_zones.name.shanghai" },
      { value: "Asia/Singapore", name: "iana_time_zones.name.singapore" },
      { value: "Asia/Taipei", name: "iana_time_zones.name.taipei" },
      { value: "Asia/Tashkent", name: "iana_time_zones.name.tashkent" },
      { value: "Asia/Tbilisi", name: "iana_time_zones.name.tbilisi" },
      { value: "Asia/Tehran", name: "iana_time_zones.name.tehran" },
      { value: "Asia/Tel_Aviv", name: "iana_time_zones.name.tel_aviv" },
      { value: "Asia/Thimbu", name: "iana_time_zones.name.thimbu" },
      { value: "Asia/Thimphu", name: "iana_time_zones.name.thimphu" },
      { value: "Asia/Tokyo", name: "iana_time_zones.name.tokyo" },
      {
        value: "Asia/Ujung_Pandang",
        name: "iana_time_zones.name.ujung_pandang",
      },
      { value: "Asia/Ulaanbaatar", name: "iana_time_zones.name.ulaanbaatar" },
      { value: "Asia/Ulan_Bator", name: "iana_time_zones.name.ulan_bator" },
      { value: "Asia/Urumqi", name: "iana_time_zones.name.urumqi" },
      { value: "Asia/Vientiane", name: "iana_time_zones.name.vientiane" },
      { value: "Asia/Vladivostok", name: "iana_time_zones.name.vladivostok" },
      { value: "Asia/Yakutsk", name: "iana_time_zones.name.yakutsk" },
      {
        value: "Asia/Yekaterinburg",
        name: "iana_time_zones.name.yekaterinburg",
      },
      { value: "Asia/Yerevan", name: "iana_time_zones.name.yerevan" },
    ],
  },
  {
    groupName: "iana_time_zones.group_name.africa",
    options: [
      { value: "Africa/Abidjan", name: "iana_time_zones.name.abidjan" },
      { value: "Africa/Accra", name: "iana_time_zones.name.accra" },
      { value: "Africa/Addis_Ababa", name: "iana_time_zones.name.addis_ababa" },
      { value: "Africa/Algiers", name: "iana_time_zones.name.algiers" },
      { value: "Africa/Asmara", name: "iana_time_zones.name.asmara" },
      { value: "Africa/Asmera", name: "iana_time_zones.name.asmera" },
      { value: "Africa/Bamako", name: "iana_time_zones.name.bamako" },
      { value: "Africa/Bangui", name: "iana_time_zones.name.bangui" },
      { value: "Africa/Banjul", name: "iana_time_zones.name.banjul" },
      { value: "Africa/Bissau", name: "iana_time_zones.name.bissau" },
      { value: "Africa/Blantyre", name: "iana_time_zones.name.blantyre" },
      { value: "Africa/Brazzaville", name: "iana_time_zones.name.brazzaville" },
      { value: "Africa/Bujumbura", name: "iana_time_zones.name.bujumbura" },
      { value: "Africa/Cairo", name: "iana_time_zones.name.cairo" },
      { value: "Africa/Casablanca", name: "iana_time_zones.name.casablanca" },
      { value: "Africa/Ceuta", name: "iana_time_zones.name.ceuta" },
      { value: "Africa/Conakry", name: "iana_time_zones.name.conakry" },
      { value: "Africa/Dakar", name: "iana_time_zones.name.dakar" },
      {
        value: "Africa/Dar_es_Salaam",
        name: "iana_time_zones.name.dar_es_salaam",
      },
      { value: "Africa/Djibouti", name: "iana_time_zones.name.djibouti" },
      { value: "Africa/Douala", name: "iana_time_zones.name.douala" },
      { value: "Africa/El_Aaiun", name: "iana_time_zones.name.el_aaiun" },
      { value: "Africa/Freetown", name: "iana_time_zones.name.freetown" },
      { value: "Africa/Gaborone", name: "iana_time_zones.name.gaborone" },
      { value: "Africa/Harare", name: "iana_time_zones.name.harare" },
      {
        value: "Africa/Johannesburg",
        name: "iana_time_zones.name.johannesburg",
      },
      { value: "Africa/Kampala", name: "iana_time_zones.name.kampala" },
      { value: "Africa/Khartoum", name: "iana_time_zones.name.khartoum" },
      { value: "Africa/Kigali", name: "iana_time_zones.name.kigali" },
      { value: "Africa/Kinshasa", name: "iana_time_zones.name.kinshasa" },
      { value: "Africa/Lagos", name: "iana_time_zones.name.lagos" },
      { value: "Africa/Libreville", name: "iana_time_zones.name.libreville" },
      { value: "Africa/Lome", name: "iana_time_zones.name.lome" },
      { value: "Africa/Luanda", name: "iana_time_zones.name.luanda" },
      { value: "Africa/Lubumbashi", name: "iana_time_zones.name.lubumbashi" },
      { value: "Africa/Lusaka", name: "iana_time_zones.name.lusaka" },
      { value: "Africa/Malabo", name: "iana_time_zones.name.malabo" },
      { value: "Africa/Maputo", name: "iana_time_zones.name.maputo" },
      { value: "Africa/Maseru", name: "iana_time_zones.name.maseru" },
      { value: "Africa/Mbabane", name: "iana_time_zones.name.mbabane" },
      { value: "Africa/Mogadishu", name: "iana_time_zones.name.mogadishu" },
      { value: "Africa/Monrovia", name: "iana_time_zones.name.monrovia" },
      { value: "Africa/Nairobi", name: "iana_time_zones.name.nairobi" },
      { value: "Africa/Ndjamena", name: "iana_time_zones.name.ndjamena" },
      { value: "Africa/Niamey", name: "iana_time_zones.name.niamey" },
      { value: "Africa/Nouakchott", name: "iana_time_zones.name.nouakchott" },
      { value: "Africa/Ouagadougou", name: "iana_time_zones.name.ouagadougou" },
      { value: "Africa/Porto-Novo", name: "iana_time_zones.name.porto_novo" },
      { value: "Africa/Sao_Tome", name: "iana_time_zones.name.sao_tome" },
      { value: "Africa/Timbuktu", name: "iana_time_zones.name.timbuktu" },
      { value: "Africa/Tripoli", name: "iana_time_zones.name.tripoli" },
      { value: "Africa/Tunis", name: "iana_time_zones.name.tunis" },
      { value: "Africa/Windhoek", name: "iana_time_zones.name.windhoek" },
    ],
  },
  {
    groupName: "iana_time_zones.group_name.australia",
    options: [
      { value: "Australia/ACT", name: "iana_time_zones.name.act" },
      { value: "Australia/Adelaide", name: "iana_time_zones.name.adelaide" },
      { value: "Australia/Brisbane", name: "iana_time_zones.name.brisbane" },
      {
        value: "Australia/Broken_Hill",
        name: "iana_time_zones.name.broken_hill",
      },
      { value: "Australia/Canberra", name: "iana_time_zones.name.canberra" },
      { value: "Australia/Currie", name: "iana_time_zones.name.currie" },
      { value: "Australia/Darwin", name: "iana_time_zones.name.darwin" },
      { value: "Australia/Eucla", name: "iana_time_zones.name.eucla" },
      { value: "Australia/Hobart", name: "iana_time_zones.name.hobart" },
      { value: "Australia/LHI", name: "iana_time_zones.name.lhi" },
      { value: "Australia/Lindeman", name: "iana_time_zones.name.lindeman" },
      { value: "Australia/Lord_Howe", name: "iana_time_zones.name.lord_howe" },
      { value: "Australia/Melbourne", name: "iana_time_zones.name.melbourne" },
      { value: "Australia/North", name: "iana_time_zones.name.north" },
      { value: "Australia/NSW", name: "iana_time_zones.name.nsw" },
      { value: "Australia/Perth", name: "iana_time_zones.name.perth" },
      {
        value: "Australia/Queensland",
        name: "iana_time_zones.name.queensland",
      },
      { value: "Australia/South", name: "iana_time_zones.name.south" },
      { value: "Australia/Sydney", name: "iana_time_zones.name.sydney" },
      { value: "Australia/Tasmania", name: "iana_time_zones.name.tasmania" },
      { value: "Australia/Victoria", name: "iana_time_zones.name.victoria" },
      { value: "Australia/West", name: "iana_time_zones.name.west" },
      {
        value: "Australia/Yancowinna",
        name: "iana_time_zones.name.yancowinna",
      },
    ],
  },
  {
    groupName: "iana_time_zones.group_name.indian",
    options: [
      {
        value: "Indian/Antananarivo",
        name: "iana_time_zones.name.antananarivo",
      },
      { value: "Indian/Chagos", name: "iana_time_zones.name.chagos" },
      { value: "Indian/Christmas", name: "iana_time_zones.name.christmas" },
      { value: "Indian/Cocos", name: "iana_time_zones.name.cocos" },
      { value: "Indian/Comoro", name: "iana_time_zones.name.comoro" },
      { value: "Indian/Kerguelen", name: "iana_time_zones.name.kerguelen" },
      { value: "Indian/Mahe", name: "iana_time_zones.name.mahe" },
      { value: "Indian/Maldives", name: "iana_time_zones.name.maldives" },
      { value: "Indian/Mauritius", name: "iana_time_zones.name.mauritius" },
      { value: "Indian/Mayotte", name: "iana_time_zones.name.mayotte" },
      { value: "Indian/Reunion", name: "iana_time_zones.name.reunion" },
    ],
  },
  {
    groupName: "iana_time_zones.group_name.atlantic",
    options: [
      { value: "Atlantic/Azores", name: "iana_time_zones.name.azores" },
      { value: "Atlantic/Bermuda", name: "iana_time_zones.name.bermuda" },
      { value: "Atlantic/Canary", name: "iana_time_zones.name.canary" },
      { value: "Atlantic/Cape_Verde", name: "iana_time_zones.name.cape_verde" },
      { value: "Atlantic/Faeroe", name: "iana_time_zones.name.faeroe" },
      { value: "Atlantic/Faroe", name: "iana_time_zones.name.faroe" },
      { value: "Atlantic/Jan_Mayen", name: "iana_time_zones.name.jan_mayen" },
      { value: "Atlantic/Madeira", name: "iana_time_zones.name.madeira" },
      { value: "Atlantic/Reykjavik", name: "iana_time_zones.name.reykjavik" },
      {
        value: "Atlantic/South_Georgia",
        name: "iana_time_zones.name.south_georgia",
      },
      { value: "Atlantic/Stanley", name: "iana_time_zones.name.stanley" },
      { value: "Atlantic/St_Helena", name: "iana_time_zones.name.st_helena" },
    ],
  },
  {
    groupName: "iana_time_zones.group_name.pacific",
    options: [
      { value: "Pacific/Apia", name: "iana_time_zones.name.apia" },
      { value: "Pacific/Auckland", name: "iana_time_zones.name.auckland" },
      { value: "Pacific/Chatham", name: "iana_time_zones.name.chatham" },
      { value: "Pacific/Easter", name: "iana_time_zones.name.easter" },
      { value: "Pacific/Efate", name: "iana_time_zones.name.efate" },
      { value: "Pacific/Enderbury", name: "iana_time_zones.name.enderbury" },
      { value: "Pacific/Fakaofo", name: "iana_time_zones.name.fakaofo" },
      { value: "Pacific/Fiji", name: "iana_time_zones.name.fiji" },
      { value: "Pacific/Funafuti", name: "iana_time_zones.name.funafuti" },
      { value: "Pacific/Galapagos", name: "iana_time_zones.name.galapagos" },
      { value: "Pacific/Gambier", name: "iana_time_zones.name.gambier" },
      {
        value: "Pacific/Guadalcanal",
        name: "iana_time_zones.name.guadalcanal",
      },
      { value: "Pacific/Guam", name: "iana_time_zones.name.guam" },
      { value: "Pacific/Honolulu", name: "iana_time_zones.name.honolulu" },
      { value: "Pacific/Johnston", name: "iana_time_zones.name.johnston" },
      { value: "Pacific/Kiritimati", name: "iana_time_zones.name.kiritimati" },
      { value: "Pacific/Kosrae", name: "iana_time_zones.name.kosrae" },
      { value: "Pacific/Kwajalein", name: "iana_time_zones.name.kwajalein" },
      { value: "Pacific/Majuro", name: "iana_time_zones.name.majuro" },
      { value: "Pacific/Marquesas", name: "iana_time_zones.name.marquesas" },
      { value: "Pacific/Midway", name: "iana_time_zones.name.midway" },
      { value: "Pacific/Nauru", name: "iana_time_zones.name.nauru" },
      { value: "Pacific/Niue", name: "iana_time_zones.name.niue" },
      { value: "Pacific/Norfolk", name: "iana_time_zones.name.norfolk" },
      { value: "Pacific/Noumea", name: "iana_time_zones.name.noumea" },
      { value: "Pacific/Pago_Pago", name: "iana_time_zones.name.pago_pago" },
      { value: "Pacific/Palau", name: "iana_time_zones.name.palau" },
      { value: "Pacific/Pitcairn", name: "iana_time_zones.name.pitcairn" },
      { value: "Pacific/Ponape", name: "iana_time_zones.name.ponape" },
      {
        value: "Pacific/Port_Moresby",
        name: "iana_time_zones.name.port_moresby",
      },
      { value: "Pacific/Rarotonga", name: "iana_time_zones.name.rarotonga" },
      { value: "Pacific/Saipan", name: "iana_time_zones.name.saipan" },
      { value: "Pacific/Samoa", name: "iana_time_zones.name.samoa" },
      { value: "Pacific/Tahiti", name: "iana_time_zones.name.tahiti" },
      { value: "Pacific/Tarawa", name: "iana_time_zones.name.tarawa" },
      { value: "Pacific/Tongatapu", name: "iana_time_zones.name.tongatapu" },
      { value: "Pacific/Truk", name: "iana_time_zones.name.truk" },
      { value: "Pacific/Wake", name: "iana_time_zones.name.wake" },
      { value: "Pacific/Wallis", name: "iana_time_zones.name.wallis" },
      { value: "Pacific/Yap", name: "iana_time_zones.name.yap" },
    ],
  },
  {
    groupName: "iana_time_zones.group_name.antarctica",
    options: [
      { value: "Antarctica/Casey", name: "iana_time_zones.name.casey" },
      { value: "Antarctica/Davis", name: "iana_time_zones.name.davis" },
      {
        value: "Antarctica/DumontDUrville",
        name: "iana_time_zones.name.dumontdurville",
      },
      { value: "Antarctica/Macquarie", name: "iana_time_zones.name.macquarie" },
      { value: "Antarctica/Mawson", name: "iana_time_zones.name.mawson" },
      { value: "Antarctica/McMurdo", name: "iana_time_zones.name.mcmurdo" },
      { value: "Antarctica/Palmer", name: "iana_time_zones.name.palmer" },
      { value: "Antarctica/Rothera", name: "iana_time_zones.name.rothera" },
      {
        value: "Antarctica/South_Pole",
        name: "iana_time_zones.name.south_pole",
      },
      { value: "Antarctica/Syowa", name: "iana_time_zones.name.syowa" },
      { value: "Antarctica/Vostok", name: "iana_time_zones.name.vostok" },
    ],
  },
  {
    groupName: "iana_time_zones.group_name.arctic",
    options: [
      {
        value: "Arctic/Longyearbyen",
        name: "iana_time_zones.name.longyearbyen",
      },
    ],
  },
  {
    groupName: "iana_time_zones.group_name.utc",
    options: [{ value: "UTC", name: "iana_time_zones.name.utc" }],
  },
  {
    groupName: "iana_time_zones.group_name.manual_offsets",
    options: [
      { value: "Etc/GMT-14", name: "iana_time_zones.name.gmt_minus_14" },
      { value: "Etc/GMT-13", name: "iana_time_zones.name.gmt_minus_13" },
      { value: "Etc/GMT-12", name: "iana_time_zones.name.gmt_minus_12" },
      { value: "Etc/GMT-11", name: "iana_time_zones.name.gmt_minus_11" },
      { value: "Etc/GMT-10", name: "iana_time_zones.name.gmt_minus_10" },
      { value: "Etc/GMT-9", name: "iana_time_zones.name.gmt_minus_9" },
      { value: "Etc/GMT-8", name: "iana_time_zones.name.gmt_minus_8" },
      { value: "Etc/GMT-7", name: "iana_time_zones.name.gmt_minus_7" },
      { value: "Etc/GMT-6", name: "iana_time_zones.name.gmt_minus_6" },
      { value: "Etc/GMT-5", name: "iana_time_zones.name.gmt_minus_5" },
      { value: "Etc/GMT-4", name: "iana_time_zones.name.gmt_minus_4" },
      { value: "Etc/GMT-3", name: "iana_time_zones.name.gmt_minus_3" },
      { value: "Etc/GMT-2", name: "iana_time_zones.name.gmt_minus_2" },
      { value: "Etc/GMT-1", name: "iana_time_zones.name.gmt_minus_1" },
      { value: "Etc/GMT+0", name: "iana_time_zones.name.gmt_plus_0" },
      { value: "Etc/GMT+1", name: "iana_time_zones.name.gmt_plus_1" },
      { value: "Etc/GMT+2", name: "iana_time_zones.name.gmt_plus_2" },
      { value: "Etc/GMT+3", name: "iana_time_zones.name.gmt_plus_3" },
      { value: "Etc/GMT+4", name: "iana_time_zones.name.gmt_plus_4" },
      { value: "Etc/GMT+5", name: "iana_time_zones.name.gmt_plus_5" },
      { value: "Etc/GMT+6", name: "iana_time_zones.name.gmt_plus_6" },
      { value: "Etc/GMT+7", name: "iana_time_zones.name.gmt_plus_7" },
      { value: "Etc/GMT+8", name: "iana_time_zones.name.gmt_plus_8" },
      { value: "Etc/GMT+9", name: "iana_time_zones.name.gmt_plus_9" },
      { value: "Etc/GMT+10", name: "iana_time_zones.name.gmt_plus_10" },
      { value: "Etc/GMT+11", name: "iana_time_zones.name.gmt_plus_11" },
      { value: "Etc/GMT+12", name: "iana_time_zones.name.gmt_plus_12" },
    ],
  },
];

const RAILS_ZONE_MAP = [
  {
    rails_zone_name: "International Date Line West",
    iana_zone_name: "Etc/GMT+12",
  },
  { rails_zone_name: "Midway Island", iana_zone_name: "Pacific/Midway" },
  { rails_zone_name: "American Samoa", iana_zone_name: "Pacific/Pago_Pago" },
  { rails_zone_name: "Hawaii", iana_zone_name: "Pacific/Honolulu" },
  { rails_zone_name: "Alaska", iana_zone_name: "America/Juneau" },
  {
    rails_zone_name: "Pacific Time (US & Canada)",
    iana_zone_name: "America/Los_Angeles",
  },
  { rails_zone_name: "Tijuana", iana_zone_name: "America/Tijuana" },
  {
    rails_zone_name: "Mountain Time (US & Canada)",
    iana_zone_name: "America/Denver",
  },
  { rails_zone_name: "Arizona", iana_zone_name: "America/Phoenix" },
  { rails_zone_name: "Chihuahua", iana_zone_name: "America/Chihuahua" },
  { rails_zone_name: "Mazatlan", iana_zone_name: "America/Mazatlan" },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/Chicago",
  },
  { rails_zone_name: "Saskatchewan", iana_zone_name: "America/Regina" },
  { rails_zone_name: "Guadalajara", iana_zone_name: "America/Mexico_City" },
  { rails_zone_name: "Mexico City", iana_zone_name: "America/Mexico_City" },
  { rails_zone_name: "Monterrey", iana_zone_name: "America/Monterrey" },
  { rails_zone_name: "Central America", iana_zone_name: "America/Guatemala" },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/New_York",
  },
  {
    rails_zone_name: "Indiana (East)",
    iana_zone_name: "America/Indiana/Indianapolis",
  },
  { rails_zone_name: "Bogota", iana_zone_name: "America/Bogota" },
  { rails_zone_name: "Lima", iana_zone_name: "America/Lima" },
  { rails_zone_name: "Quito", iana_zone_name: "America/Lima" },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Halifax",
  },
  { rails_zone_name: "Caracas", iana_zone_name: "America/Caracas" },
  { rails_zone_name: "La Paz", iana_zone_name: "America/La_Paz" },
  { rails_zone_name: "Santiago", iana_zone_name: "America/Santiago" },
  { rails_zone_name: "Newfoundland", iana_zone_name: "America/St_Johns" },
  { rails_zone_name: "Brasilia", iana_zone_name: "America/Sao_Paulo" },
  {
    rails_zone_name: "Buenos Aires",
    iana_zone_name: "America/Argentina/Buenos_Aires",
  },
  { rails_zone_name: "Montevideo", iana_zone_name: "America/Montevideo" },
  { rails_zone_name: "Georgetown", iana_zone_name: "America/Guyana" },
  { rails_zone_name: "Puerto Rico", iana_zone_name: "America/Puerto_Rico" },
  { rails_zone_name: "Greenland", iana_zone_name: "America/Godthab" },
  { rails_zone_name: "Mid-Atlantic", iana_zone_name: "Atlantic/South_Georgia" },
  { rails_zone_name: "Azores", iana_zone_name: "Atlantic/Azores" },
  { rails_zone_name: "Cape Verde Is.", iana_zone_name: "Atlantic/Cape_Verde" },
  { rails_zone_name: "Dublin", iana_zone_name: "Europe/Dublin" },
  { rails_zone_name: "Edinburgh", iana_zone_name: "Europe/London" },
  { rails_zone_name: "Lisbon", iana_zone_name: "Europe/Lisbon" },
  { rails_zone_name: "London", iana_zone_name: "Europe/London" },
  { rails_zone_name: "Casablanca", iana_zone_name: "Africa/Casablanca" },
  { rails_zone_name: "Monrovia", iana_zone_name: "Africa/Monrovia" },
  { rails_zone_name: "UTC", iana_zone_name: "Etc/UTC" },
  { rails_zone_name: "Belgrade", iana_zone_name: "Europe/Belgrade" },
  { rails_zone_name: "Bratislava", iana_zone_name: "Europe/Bratislava" },
  { rails_zone_name: "Budapest", iana_zone_name: "Europe/Budapest" },
  { rails_zone_name: "Ljubljana", iana_zone_name: "Europe/Ljubljana" },
  { rails_zone_name: "Prague", iana_zone_name: "Europe/Prague" },
  { rails_zone_name: "Sarajevo", iana_zone_name: "Europe/Sarajevo" },
  { rails_zone_name: "Skopje", iana_zone_name: "Europe/Skopje" },
  { rails_zone_name: "Warsaw", iana_zone_name: "Europe/Warsaw" },
  { rails_zone_name: "Zagreb", iana_zone_name: "Europe/Zagreb" },
  { rails_zone_name: "Brussels", iana_zone_name: "Europe/Brussels" },
  { rails_zone_name: "Copenhagen", iana_zone_name: "Europe/Copenhagen" },
  { rails_zone_name: "Madrid", iana_zone_name: "Europe/Madrid" },
  { rails_zone_name: "Paris", iana_zone_name: "Europe/Paris" },
  { rails_zone_name: "Amsterdam", iana_zone_name: "Europe/Amsterdam" },
  { rails_zone_name: "Berlin", iana_zone_name: "Europe/Berlin" },
  { rails_zone_name: "Bern", iana_zone_name: "Europe/Zurich" },
  { rails_zone_name: "Zurich", iana_zone_name: "Europe/Zurich" },
  { rails_zone_name: "Rome", iana_zone_name: "Europe/Rome" },
  { rails_zone_name: "Stockholm", iana_zone_name: "Europe/Stockholm" },
  { rails_zone_name: "Vienna", iana_zone_name: "Europe/Vienna" },
  { rails_zone_name: "West Central Africa", iana_zone_name: "Africa/Algiers" },
  { rails_zone_name: "Bucharest", iana_zone_name: "Europe/Bucharest" },
  { rails_zone_name: "Cairo", iana_zone_name: "Africa/Cairo" },
  { rails_zone_name: "Helsinki", iana_zone_name: "Europe/Helsinki" },
  { rails_zone_name: "Kyiv", iana_zone_name: "Europe/Kiev" },
  { rails_zone_name: "Riga", iana_zone_name: "Europe/Riga" },
  { rails_zone_name: "Sofia", iana_zone_name: "Europe/Sofia" },
  { rails_zone_name: "Tallinn", iana_zone_name: "Europe/Tallinn" },
  { rails_zone_name: "Vilnius", iana_zone_name: "Europe/Vilnius" },
  { rails_zone_name: "Athens", iana_zone_name: "Europe/Athens" },
  { rails_zone_name: "Istanbul", iana_zone_name: "Europe/Istanbul" },
  { rails_zone_name: "Minsk", iana_zone_name: "Europe/Minsk" },
  { rails_zone_name: "Jerusalem", iana_zone_name: "Asia/Jerusalem" },
  { rails_zone_name: "Harare", iana_zone_name: "Africa/Harare" },
  { rails_zone_name: "Pretoria", iana_zone_name: "Africa/Johannesburg" },
  { rails_zone_name: "Kaliningrad", iana_zone_name: "Europe/Kaliningrad" },
  { rails_zone_name: "Moscow", iana_zone_name: "Europe/Moscow" },
  { rails_zone_name: "St. Petersburg", iana_zone_name: "Europe/Moscow" },
  { rails_zone_name: "Volgograd", iana_zone_name: "Europe/Volgograd" },
  { rails_zone_name: "Samara", iana_zone_name: "Europe/Samara" },
  { rails_zone_name: "Kuwait", iana_zone_name: "Asia/Kuwait" },
  { rails_zone_name: "Riyadh", iana_zone_name: "Asia/Riyadh" },
  { rails_zone_name: "Nairobi", iana_zone_name: "Africa/Nairobi" },
  { rails_zone_name: "Baghdad", iana_zone_name: "Asia/Baghdad" },
  { rails_zone_name: "Tehran", iana_zone_name: "Asia/Tehran" },
  { rails_zone_name: "Abu Dhabi", iana_zone_name: "Asia/Muscat" },
  { rails_zone_name: "Muscat", iana_zone_name: "Asia/Muscat" },
  { rails_zone_name: "Baku", iana_zone_name: "Asia/Baku" },
  { rails_zone_name: "Tbilisi", iana_zone_name: "Asia/Tbilisi" },
  { rails_zone_name: "Yerevan", iana_zone_name: "Asia/Yerevan" },
  { rails_zone_name: "Kabul", iana_zone_name: "Asia/Kabul" },
  { rails_zone_name: "Ekaterinburg", iana_zone_name: "Asia/Yekaterinburg" },
  { rails_zone_name: "Islamabad", iana_zone_name: "Asia/Karachi" },
  { rails_zone_name: "Karachi", iana_zone_name: "Asia/Karachi" },
  { rails_zone_name: "Tashkent", iana_zone_name: "Asia/Tashkent" },
  { rails_zone_name: "Chennai", iana_zone_name: "Asia/Kolkata" },
  { rails_zone_name: "Kolkata", iana_zone_name: "Asia/Kolkata" },
  { rails_zone_name: "Mumbai", iana_zone_name: "Asia/Kolkata" },
  { rails_zone_name: "New Delhi", iana_zone_name: "Asia/Kolkata" },
  { rails_zone_name: "Kathmandu", iana_zone_name: "Asia/Kathmandu" },
  { rails_zone_name: "Astana", iana_zone_name: "Asia/Dhaka" },
  { rails_zone_name: "Dhaka", iana_zone_name: "Asia/Dhaka" },
  { rails_zone_name: "Sri Jayawardenepura", iana_zone_name: "Asia/Colombo" },
  { rails_zone_name: "Almaty", iana_zone_name: "Asia/Almaty" },
  { rails_zone_name: "Novosibirsk", iana_zone_name: "Asia/Novosibirsk" },
  { rails_zone_name: "Rangoon", iana_zone_name: "Asia/Rangoon" },
  { rails_zone_name: "Bangkok", iana_zone_name: "Asia/Bangkok" },
  { rails_zone_name: "Hanoi", iana_zone_name: "Asia/Bangkok" },
  { rails_zone_name: "Jakarta", iana_zone_name: "Asia/Jakarta" },
  { rails_zone_name: "Krasnoyarsk", iana_zone_name: "Asia/Krasnoyarsk" },
  { rails_zone_name: "Beijing", iana_zone_name: "Asia/Shanghai" },
  { rails_zone_name: "Chongqing", iana_zone_name: "Asia/Chongqing" },
  { rails_zone_name: "Hong Kong", iana_zone_name: "Asia/Hong_Kong" },
  { rails_zone_name: "Urumqi", iana_zone_name: "Asia/Urumqi" },
  { rails_zone_name: "Kuala Lumpur", iana_zone_name: "Asia/Kuala_Lumpur" },
  { rails_zone_name: "Singapore", iana_zone_name: "Asia/Singapore" },
  { rails_zone_name: "Taipei", iana_zone_name: "Asia/Taipei" },
  { rails_zone_name: "Perth", iana_zone_name: "Australia/Perth" },
  { rails_zone_name: "Irkutsk", iana_zone_name: "Asia/Irkutsk" },
  { rails_zone_name: "Ulaanbaatar", iana_zone_name: "Asia/Ulaanbaatar" },
  { rails_zone_name: "Seoul", iana_zone_name: "Asia/Seoul" },
  { rails_zone_name: "Osaka", iana_zone_name: "Asia/Tokyo" },
  { rails_zone_name: "Sapporo", iana_zone_name: "Asia/Tokyo" },
  { rails_zone_name: "Tokyo", iana_zone_name: "Asia/Tokyo" },
  { rails_zone_name: "Yakutsk", iana_zone_name: "Asia/Yakutsk" },
  { rails_zone_name: "Darwin", iana_zone_name: "Australia/Darwin" },
  { rails_zone_name: "Adelaide", iana_zone_name: "Australia/Adelaide" },
  { rails_zone_name: "Canberra", iana_zone_name: "Australia/Melbourne" },
  { rails_zone_name: "Melbourne", iana_zone_name: "Australia/Melbourne" },
  { rails_zone_name: "Sydney", iana_zone_name: "Australia/Sydney" },
  { rails_zone_name: "Brisbane", iana_zone_name: "Australia/Brisbane" },
  { rails_zone_name: "Hobart", iana_zone_name: "Australia/Hobart" },
  { rails_zone_name: "Vladivostok", iana_zone_name: "Asia/Vladivostok" },
  { rails_zone_name: "Guam", iana_zone_name: "Pacific/Guam" },
  { rails_zone_name: "Port Moresby", iana_zone_name: "Pacific/Port_Moresby" },
  { rails_zone_name: "Magadan", iana_zone_name: "Asia/Magadan" },
  { rails_zone_name: "Srednekolymsk", iana_zone_name: "Asia/Srednekolymsk" },
  { rails_zone_name: "Solomon Is.", iana_zone_name: "Pacific/Guadalcanal" },
  { rails_zone_name: "New Caledonia", iana_zone_name: "Pacific/Noumea" },
  { rails_zone_name: "Fiji", iana_zone_name: "Pacific/Fiji" },
  { rails_zone_name: "Kamchatka", iana_zone_name: "Asia/Kamchatka" },
  { rails_zone_name: "Marshall Is.", iana_zone_name: "Pacific/Majuro" },
  { rails_zone_name: "Auckland", iana_zone_name: "Pacific/Auckland" },
  { rails_zone_name: "Wellington", iana_zone_name: "Pacific/Auckland" },
  { rails_zone_name: "Nuku'alofa", iana_zone_name: "Pacific/Tongatapu" },
  { rails_zone_name: "Tokelau Is.", iana_zone_name: "Pacific/Fakaofo" },
  { rails_zone_name: "Chatham Is.", iana_zone_name: "Pacific/Chatham" },
  { rails_zone_name: "Samoa", iana_zone_name: "Pacific/Apia" },
  { rails_zone_name: "Buenos Aires", iana_zone_name: "America/Buenos_Aires" },
  { rails_zone_name: "Alaska", iana_zone_name: "America/Anchorage" },
  { rails_zone_name: "Hawaii", iana_zone_name: "America/Adak" },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Anguilla",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Antigua",
  },
  { rails_zone_name: "Buenos Aires", iana_zone_name: "America/Araguaina" },
  {
    rails_zone_name: "Buenos Aires",
    iana_zone_name: "America/Argentina/Catamarca",
  },
  { rails_zone_name: "Buenos Aires", iana_zone_name: "America/Catamarca" },
  {
    rails_zone_name: "Buenos Aires",
    iana_zone_name: "America/Argentina/Cordoba",
  },
  {
    rails_zone_name: "Buenos Aires",
    iana_zone_name: "America/Argentina/Jujuy",
  },
  {
    rails_zone_name: "Buenos Aires",
    iana_zone_name: "America/Argentina/La_Rioja",
  },
  {
    rails_zone_name: "Buenos Aires",
    iana_zone_name: "America/Argentina/Mendoza",
  },
  {
    rails_zone_name: "Buenos Aires",
    iana_zone_name: "America/Argentina/Rio_Gallegos",
  },
  {
    rails_zone_name: "Buenos Aires",
    iana_zone_name: "America/Argentina/Salta",
  },
  {
    rails_zone_name: "Buenos Aires",
    iana_zone_name: "America/Argentina/San_Juan",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Argentina/San_Luis",
  },
  {
    rails_zone_name: "Buenos Aires",
    iana_zone_name: "America/Argentina/Tucuman",
  },
  {
    rails_zone_name: "Buenos Aires",
    iana_zone_name: "America/Argentina/Ushuaia",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Aruba",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Asuncion",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Atikokan",
  },
  { rails_zone_name: "Buenos Aires", iana_zone_name: "America/Bahia" },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Barbados",
  },
  { rails_zone_name: "Buenos Aires", iana_zone_name: "America/Belem" },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/Belize",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Blanc-Sablon",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Boa_Vista",
  },
  {
    rails_zone_name: "Mountain Time (US & Canada)",
    iana_zone_name: "America/Boise",
  },
  {
    rails_zone_name: "Mountain Time (US & Canada)",
    iana_zone_name: "America/Cambridge_Bay",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Campo_Grande",
  },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/Cancun",
  },
  { rails_zone_name: "Buenos Aires", iana_zone_name: "America/Cayenne" },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Cayman",
  },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/Costa_Rica",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Cuiaba",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Curacao",
  },
  { rails_zone_name: "London", iana_zone_name: "America/Danmarkshavn" },
  {
    rails_zone_name: "Pacific Time (US & Canada)",
    iana_zone_name: "America/Dawson",
  },
  {
    rails_zone_name: "Mountain Time (US & Canada)",
    iana_zone_name: "America/Dawson_Creek",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Detroit",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Dominica",
  },
  {
    rails_zone_name: "Mountain Time (US & Canada)",
    iana_zone_name: "America/Edmonton",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Eirunepe",
  },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/El_Salvador",
  },
  { rails_zone_name: "Buenos Aires", iana_zone_name: "America/Fortaleza" },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Glace_Bay",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Goose_Bay",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Grand_Turk",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Grenada",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Guadeloupe",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Guayaquil",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Havana",
  },
  {
    rails_zone_name: "Mountain Time (US & Canada)",
    iana_zone_name: "America/Hermosillo",
  },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/Indiana/Knox",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Indiana/Marengo",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Indiana/Petersburg",
  },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/Indiana/Tell_City",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Indiana/Vevay",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Indiana/Vincennes",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Indiana/Winamac",
  },
  {
    rails_zone_name: "Mountain Time (US & Canada)",
    iana_zone_name: "America/Inuvik",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Iqaluit",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Jamaica",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Kentucky/Louisville",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Kentucky/Monticello",
  },
  { rails_zone_name: "Buenos Aires", iana_zone_name: "America/Maceio" },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/Managua",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Manaus",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Marigot",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Martinique",
  },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/Matamoros",
  },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/Menominee",
  },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/Merida",
  },
  { rails_zone_name: "Buenos Aires", iana_zone_name: "America/Miquelon" },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Moncton",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Montreal",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Montserrat",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Nassau",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Nipigon",
  },
  { rails_zone_name: "Alaska", iana_zone_name: "America/Nome" },
  { rails_zone_name: "Mid-Atlantic", iana_zone_name: "America/Noronha" },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/North_Dakota/Center",
  },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/North_Dakota/New_Salem",
  },
  {
    rails_zone_name: "Mountain Time (US & Canada)",
    iana_zone_name: "America/Ojinaga",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Panama",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Pangnirtung",
  },
  { rails_zone_name: "Buenos Aires", iana_zone_name: "America/Paramaribo" },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Port-au-Prince",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Port_of_Spain",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Porto_Velho",
  },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/Rainy_River",
  },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/Rankin_Inlet",
  },
  { rails_zone_name: "Buenos Aires", iana_zone_name: "America/Recife" },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Resolute",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Rio_Branco",
  },
  {
    rails_zone_name: "Pacific Time (US & Canada)",
    iana_zone_name: "America/Santa_Isabel",
  },
  { rails_zone_name: "Buenos Aires", iana_zone_name: "America/Santarem" },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Santo_Domingo",
  },
  { rails_zone_name: "Cape Verde Is.", iana_zone_name: "America/Scoresbysund" },
  {
    rails_zone_name: "Mountain Time (US & Canada)",
    iana_zone_name: "America/Shiprock",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/St_Barthelemy",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/St_Kitts",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/St_Lucia",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/St_Thomas",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/St_Vincent",
  },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/Swift_Current",
  },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/Tegucigalpa",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Thule",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Thunder_Bay",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Toronto",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Tortola",
  },
  {
    rails_zone_name: "Pacific Time (US & Canada)",
    iana_zone_name: "America/Vancouver",
  },
  {
    rails_zone_name: "Pacific Time (US & Canada)",
    iana_zone_name: "America/Whitehorse",
  },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/Winnipeg",
  },
  { rails_zone_name: "Alaska", iana_zone_name: "America/Yakutat" },
  {
    rails_zone_name: "Mountain Time (US & Canada)",
    iana_zone_name: "America/Yellowknife",
  },
  { rails_zone_name: "Paris", iana_zone_name: "Europe/Andorra" },
  { rails_zone_name: "Cairo", iana_zone_name: "Europe/Chisinau" },
  { rails_zone_name: "Paris", iana_zone_name: "Europe/Gibraltar" },
  { rails_zone_name: "London", iana_zone_name: "Europe/Guernsey" },
  { rails_zone_name: "London", iana_zone_name: "Europe/Isle_of_Man" },
  { rails_zone_name: "London", iana_zone_name: "Europe/Jersey" },
  { rails_zone_name: "Paris", iana_zone_name: "Europe/Luxembourg" },
  { rails_zone_name: "Paris", iana_zone_name: "Europe/Malta" },
  { rails_zone_name: "Cairo", iana_zone_name: "Europe/Mariehamn" },
  { rails_zone_name: "Paris", iana_zone_name: "Europe/Monaco" },
  { rails_zone_name: "Paris", iana_zone_name: "Europe/Oslo" },
  { rails_zone_name: "Paris", iana_zone_name: "Europe/Podgorica" },
  { rails_zone_name: "Paris", iana_zone_name: "Europe/San_Marino" },
  { rails_zone_name: "Cairo", iana_zone_name: "Europe/Simferopol" },
  { rails_zone_name: "Paris", iana_zone_name: "Europe/Tirane" },
  { rails_zone_name: "Cairo", iana_zone_name: "Europe/Uzhgorod" },
  { rails_zone_name: "Paris", iana_zone_name: "Europe/Vaduz" },
  { rails_zone_name: "Paris", iana_zone_name: "Europe/Vatican" },
  { rails_zone_name: "Cairo", iana_zone_name: "Europe/Zaporozhye" },
  { rails_zone_name: "Moscow", iana_zone_name: "Asia/Aden" },
  { rails_zone_name: "Cairo", iana_zone_name: "Asia/Amman" },
  { rails_zone_name: "Solomon Is.", iana_zone_name: "Asia/Anadyr" },
  { rails_zone_name: "Karachi", iana_zone_name: "Asia/Aqtau" },
  { rails_zone_name: "Karachi", iana_zone_name: "Asia/Aqtobe" },
  { rails_zone_name: "Karachi", iana_zone_name: "Asia/Ashgabat" },
  { rails_zone_name: "Moscow", iana_zone_name: "Asia/Bahrain" },
  { rails_zone_name: "Cairo", iana_zone_name: "Asia/Beirut" },
  { rails_zone_name: "Dhaka", iana_zone_name: "Asia/Bishkek" },
  { rails_zone_name: "Hong Kong", iana_zone_name: "Asia/Brunei" },
  { rails_zone_name: "Hong Kong", iana_zone_name: "Asia/Choibalsan" },
  { rails_zone_name: "Cairo", iana_zone_name: "Asia/Damascus" },
  { rails_zone_name: "Tokyo", iana_zone_name: "Asia/Dili" },
  { rails_zone_name: "Baku", iana_zone_name: "Asia/Dubai" },
  { rails_zone_name: "Karachi", iana_zone_name: "Asia/Dushanbe" },
  { rails_zone_name: "Cairo", iana_zone_name: "Asia/Gaza" },
  { rails_zone_name: "Hong Kong", iana_zone_name: "Asia/Harbin" },
  { rails_zone_name: "Jakarta", iana_zone_name: "Asia/Ho_Chi_Minh" },
  { rails_zone_name: "Jakarta", iana_zone_name: "Asia/Hovd" },
  { rails_zone_name: "Tokyo", iana_zone_name: "Asia/Jayapura" },
  { rails_zone_name: "Hong Kong", iana_zone_name: "Asia/Kashgar" },
  { rails_zone_name: "Hong Kong", iana_zone_name: "Asia/Kuching" },
  { rails_zone_name: "Hong Kong", iana_zone_name: "Asia/Macau" },
  { rails_zone_name: "Hong Kong", iana_zone_name: "Asia/Makassar" },
  { rails_zone_name: "Hong Kong", iana_zone_name: "Asia/Manila" },
  { rails_zone_name: "Cairo", iana_zone_name: "Asia/Nicosia" },
  { rails_zone_name: "Dhaka", iana_zone_name: "Asia/Novokuznetsk" },
  { rails_zone_name: "Dhaka", iana_zone_name: "Asia/Omsk" },
  { rails_zone_name: "Karachi", iana_zone_name: "Asia/Oral" },
  { rails_zone_name: "Jakarta", iana_zone_name: "Asia/Phnom_Penh" },
  { rails_zone_name: "Jakarta", iana_zone_name: "Asia/Pontianak" },
  { rails_zone_name: "Tokyo", iana_zone_name: "Asia/Pyongyang" },
  { rails_zone_name: "Moscow", iana_zone_name: "Asia/Qatar" },
  { rails_zone_name: "Dhaka", iana_zone_name: "Asia/Qyzylorda" },
  { rails_zone_name: "Sydney", iana_zone_name: "Asia/Sakhalin" },
  { rails_zone_name: "Karachi", iana_zone_name: "Asia/Samarkand" },
  { rails_zone_name: "Dhaka", iana_zone_name: "Asia/Thimphu" },
  { rails_zone_name: "Jakarta", iana_zone_name: "Asia/Vientiane" },
  { rails_zone_name: "London", iana_zone_name: "Africa/Abidjan" },
  { rails_zone_name: "London", iana_zone_name: "Africa/Accra" },
  { rails_zone_name: "Moscow", iana_zone_name: "Africa/Addis_Ababa" },
  { rails_zone_name: "Moscow", iana_zone_name: "Africa/Asmara" },
  { rails_zone_name: "London", iana_zone_name: "Africa/Bamako" },
  { rails_zone_name: "Paris", iana_zone_name: "Africa/Bangui" },
  { rails_zone_name: "London", iana_zone_name: "Africa/Banjul" },
  { rails_zone_name: "London", iana_zone_name: "Africa/Bissau" },
  { rails_zone_name: "Cairo", iana_zone_name: "Africa/Blantyre" },
  { rails_zone_name: "Paris", iana_zone_name: "Africa/Brazzaville" },
  { rails_zone_name: "Cairo", iana_zone_name: "Africa/Bujumbura" },
  { rails_zone_name: "Paris", iana_zone_name: "Africa/Ceuta" },
  { rails_zone_name: "London", iana_zone_name: "Africa/Conakry" },
  { rails_zone_name: "London", iana_zone_name: "Africa/Dakar" },
  { rails_zone_name: "Moscow", iana_zone_name: "Africa/Dar_es_Salaam" },
  { rails_zone_name: "Moscow", iana_zone_name: "Africa/Djibouti" },
  { rails_zone_name: "Paris", iana_zone_name: "Africa/Douala" },
  { rails_zone_name: "London", iana_zone_name: "Africa/El_Aaiun" },
  { rails_zone_name: "London", iana_zone_name: "Africa/Freetown" },
  { rails_zone_name: "Cairo", iana_zone_name: "Africa/Gaborone" },
  { rails_zone_name: "Moscow", iana_zone_name: "Africa/Kampala" },
  { rails_zone_name: "Moscow", iana_zone_name: "Africa/Khartoum" },
  { rails_zone_name: "Cairo", iana_zone_name: "Africa/Kigali" },
  { rails_zone_name: "Paris", iana_zone_name: "Africa/Kinshasa" },
  { rails_zone_name: "Paris", iana_zone_name: "Africa/Lagos" },
  { rails_zone_name: "Paris", iana_zone_name: "Africa/Libreville" },
  { rails_zone_name: "London", iana_zone_name: "Africa/Lome" },
  { rails_zone_name: "Paris", iana_zone_name: "Africa/Luanda" },
  { rails_zone_name: "Cairo", iana_zone_name: "Africa/Lubumbashi" },
  { rails_zone_name: "Cairo", iana_zone_name: "Africa/Lusaka" },
  { rails_zone_name: "Paris", iana_zone_name: "Africa/Malabo" },
  { rails_zone_name: "Cairo", iana_zone_name: "Africa/Maputo" },
  { rails_zone_name: "Cairo", iana_zone_name: "Africa/Maseru" },
  { rails_zone_name: "Cairo", iana_zone_name: "Africa/Mbabane" },
  { rails_zone_name: "Moscow", iana_zone_name: "Africa/Mogadishu" },
  { rails_zone_name: "Paris", iana_zone_name: "Africa/Ndjamena" },
  { rails_zone_name: "Paris", iana_zone_name: "Africa/Niamey" },
  { rails_zone_name: "London", iana_zone_name: "Africa/Nouakchott" },
  { rails_zone_name: "London", iana_zone_name: "Africa/Ouagadougou" },
  { rails_zone_name: "Paris", iana_zone_name: "Africa/Porto-Novo" },
  { rails_zone_name: "London", iana_zone_name: "Africa/Sao_Tome" },
  { rails_zone_name: "Cairo", iana_zone_name: "Africa/Tripoli" },
  { rails_zone_name: "Paris", iana_zone_name: "Africa/Tunis" },
  { rails_zone_name: "Paris", iana_zone_name: "Africa/Windhoek" },
  { rails_zone_name: "Adelaide", iana_zone_name: "Australia/Broken_Hill" },
  { rails_zone_name: "Sydney", iana_zone_name: "Australia/Currie" },
  { rails_zone_name: "Sydney", iana_zone_name: "Australia/Lindeman" },
  { rails_zone_name: "Moscow", iana_zone_name: "Indian/Antananarivo" },
  { rails_zone_name: "Dhaka", iana_zone_name: "Indian/Chagos" },
  { rails_zone_name: "Jakarta", iana_zone_name: "Indian/Christmas" },
  { rails_zone_name: "Rangoon", iana_zone_name: "Indian/Cocos" },
  { rails_zone_name: "Moscow", iana_zone_name: "Indian/Comoro" },
  { rails_zone_name: "Karachi", iana_zone_name: "Indian/Kerguelen" },
  { rails_zone_name: "Baku", iana_zone_name: "Indian/Mahe" },
  { rails_zone_name: "Karachi", iana_zone_name: "Indian/Maldives" },
  { rails_zone_name: "Baku", iana_zone_name: "Indian/Mauritius" },
  { rails_zone_name: "Moscow", iana_zone_name: "Indian/Mayotte" },
  { rails_zone_name: "Baku", iana_zone_name: "Indian/Reunion" },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "Atlantic/Bermuda",
  },
  { rails_zone_name: "London", iana_zone_name: "Atlantic/Canary" },
  { rails_zone_name: "London", iana_zone_name: "Atlantic/Faroe" },
  { rails_zone_name: "London", iana_zone_name: "Atlantic/Madeira" },
  { rails_zone_name: "London", iana_zone_name: "Atlantic/Reykjavik" },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "Atlantic/Stanley",
  },
  { rails_zone_name: "London", iana_zone_name: "Atlantic/St_Helena" },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "Pacific/Easter",
  },
  { rails_zone_name: "Solomon Is.", iana_zone_name: "Pacific/Efate" },
  { rails_zone_name: "Nuku'alofa", iana_zone_name: "Pacific/Enderbury" },
  { rails_zone_name: "Auckland", iana_zone_name: "Pacific/Funafuti" },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "Pacific/Galapagos",
  },
  { rails_zone_name: "Alaska", iana_zone_name: "Pacific/Gambier" },
  { rails_zone_name: "Hawaii", iana_zone_name: "Pacific/Johnston" },
  { rails_zone_name: "Solomon Is.", iana_zone_name: "Pacific/Kosrae" },
  { rails_zone_name: "Auckland", iana_zone_name: "Pacific/Kwajalein" },
  { rails_zone_name: "Auckland", iana_zone_name: "Pacific/Nauru" },
  { rails_zone_name: "Samoa", iana_zone_name: "Pacific/Niue" },
  { rails_zone_name: "Tokyo", iana_zone_name: "Pacific/Palau" },
  {
    rails_zone_name: "Pacific Time (US & Canada)",
    iana_zone_name: "Pacific/Pitcairn",
  },
  { rails_zone_name: "Solomon Is.", iana_zone_name: "Pacific/Ponape" },
  { rails_zone_name: "Hawaii", iana_zone_name: "Pacific/Rarotonga" },
  { rails_zone_name: "Sydney", iana_zone_name: "Pacific/Saipan" },
  { rails_zone_name: "Hawaii", iana_zone_name: "Pacific/Tahiti" },
  { rails_zone_name: "Auckland", iana_zone_name: "Pacific/Tarawa" },
  { rails_zone_name: "Sydney", iana_zone_name: "Pacific/Truk" },
  { rails_zone_name: "Auckland", iana_zone_name: "Pacific/Wake" },
  { rails_zone_name: "Auckland", iana_zone_name: "Pacific/Wallis" },
  { rails_zone_name: "Hong Kong", iana_zone_name: "Antarctica/Casey" },
  { rails_zone_name: "Jakarta", iana_zone_name: "Antarctica/Davis" },
  { rails_zone_name: "Sydney", iana_zone_name: "Antarctica/DumontDUrville" },
  { rails_zone_name: "Dhaka", iana_zone_name: "Antarctica/Mawson" },
  { rails_zone_name: "Auckland", iana_zone_name: "Antarctica/McMurdo" },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "Antarctica/Palmer",
  },
  { rails_zone_name: "Buenos Aires", iana_zone_name: "Antarctica/Rothera" },
  { rails_zone_name: "Auckland", iana_zone_name: "Antarctica/South_Pole" },
  { rails_zone_name: "Moscow", iana_zone_name: "Antarctica/Syowa" },
  { rails_zone_name: "London", iana_zone_name: "Antarctica/Vostok" },
  { rails_zone_name: "Paris", iana_zone_name: "Arctic/Longyearbyen" },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Coral_Harbour",
  },
  { rails_zone_name: "Hawaii", iana_zone_name: "America/Atka" },
  { rails_zone_name: "Buenos Aires", iana_zone_name: "America/Cordoba" },
  { rails_zone_name: "Buenos Aires", iana_zone_name: "America/Jujuy" },
  { rails_zone_name: "Buenos Aires", iana_zone_name: "America/Mendoza" },
  {
    rails_zone_name: "Pacific Time (US & Canada)",
    iana_zone_name: "America/Ensenada",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Fort_Wayne",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Indianapolis",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Indianapolis",
  },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "America/Louisville",
  },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "America/Knox_IN",
  },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Porto_Acre",
  },
  { rails_zone_name: "Buenos Aires", iana_zone_name: "America/Rosario" },
  {
    rails_zone_name: "Atlantic Time (Canada)",
    iana_zone_name: "America/Virgin",
  },
  { rails_zone_name: "London", iana_zone_name: "Europe/Belfast" },
  { rails_zone_name: "London", iana_zone_name: "Europe/Belfast" },
  { rails_zone_name: "Moscow", iana_zone_name: "Europe/Nicosia" },
  { rails_zone_name: "Moscow", iana_zone_name: "Europe/Tiraspol" },
  { rails_zone_name: "Ekaterinburg", iana_zone_name: "Asia/Ashkhabad" },
  { rails_zone_name: "Mumbai", iana_zone_name: "Asia/Calcutta" },
  { rails_zone_name: "Mumbai", iana_zone_name: "Asia/Calcutta" },
  { rails_zone_name: "Beijing", iana_zone_name: "Asia/Chungking" },
  { rails_zone_name: "Dhaka", iana_zone_name: "Asia/Dacca" },
  { rails_zone_name: "Istanbul", iana_zone_name: "Asia/Istanbul" },
  { rails_zone_name: "Kathmandu", iana_zone_name: "Asia/Katmandu" },
  { rails_zone_name: "Hong Kong", iana_zone_name: "Asia/Macao" },
  { rails_zone_name: "Jakarta", iana_zone_name: "Asia/Saigon" },
  { rails_zone_name: "Jerusalem", iana_zone_name: "Asia/Tel_Aviv" },
  { rails_zone_name: "Dhaka", iana_zone_name: "Asia/Thimbu" },
  { rails_zone_name: "Hong Kong", iana_zone_name: "Asia/Ujung_Pandang" },
  { rails_zone_name: "Ulaanbaatar", iana_zone_name: "Asia/Ulan_Bator" },
  { rails_zone_name: "Moscow", iana_zone_name: "Africa/Asmera" },
  { rails_zone_name: "London", iana_zone_name: "Africa/Timbuktu" },
  { rails_zone_name: "London", iana_zone_name: "Africa/Timbuktu" },
  { rails_zone_name: "Sydney", iana_zone_name: "Australia/ACT" },
  { rails_zone_name: "Sydney", iana_zone_name: "Australia/NSW" },
  { rails_zone_name: "Sydney", iana_zone_name: "Australia/Canberra" },
  { rails_zone_name: "Perth", iana_zone_name: "Australia/Eucla" },
  { rails_zone_name: "Darwin", iana_zone_name: "Australia/North" },
  { rails_zone_name: "Brisbane", iana_zone_name: "Australia/Queensland" },
  { rails_zone_name: "Melbourne", iana_zone_name: "Australia/Victoria" },
  { rails_zone_name: "Perth", iana_zone_name: "Australia/West" },
  { rails_zone_name: "Hobart", iana_zone_name: "Australia/Tasmania" },
  { rails_zone_name: "Perth", iana_zone_name: "Australia/West" },
  { rails_zone_name: "Sydney", iana_zone_name: "Australia/Canberra" },
  { rails_zone_name: "Sydney", iana_zone_name: "Australia/LHI" },
  { rails_zone_name: "Sydney", iana_zone_name: "Australia/Lord_Howe" },
  { rails_zone_name: "Adelaide", iana_zone_name: "Australia/South" },
  { rails_zone_name: "Adelaide", iana_zone_name: "Australia/Yancowinna" },
  { rails_zone_name: "Dublin", iana_zone_name: "Atlantic/Faeroe" },
  { rails_zone_name: "London", iana_zone_name: "Atlantic/Jan_Mayen" },
  { rails_zone_name: "Midway Island", iana_zone_name: "Pacific/Kiritimati" },
  { rails_zone_name: "Hawaii", iana_zone_name: "Pacific/Marquesas" },
  { rails_zone_name: "Magadan", iana_zone_name: "Pacific/Norfolk" },
  { rails_zone_name: "Fiji", iana_zone_name: "Pacific/Samoa" },
  { rails_zone_name: "Guam", iana_zone_name: "Pacific/Yap" },
  { rails_zone_name: "Auckland", iana_zone_name: "Pacific/Macquarie" },
  { rails_zone_name: "UTC", iana_zone_name: "UTC" },
  { rails_zone_name: "Midway Island", iana_zone_name: "Etc/GMT-14" },
  { rails_zone_name: "Nuku'alofa", iana_zone_name: "Etc/GMT-13" },
  { rails_zone_name: "Auckland", iana_zone_name: "Etc/GMT-12" },
  { rails_zone_name: "Magadan", iana_zone_name: "Etc/GMT-11" },
  { rails_zone_name: "Sydney", iana_zone_name: "Etc/GMT-10" },
  { rails_zone_name: "Tokyo", iana_zone_name: "Etc/GMT-9" },
  { rails_zone_name: "Beijing", iana_zone_name: "Etc/GMT-8" },
  { rails_zone_name: "Bangkok", iana_zone_name: "Etc/GMT-7" },
  { rails_zone_name: "Dhaka", iana_zone_name: "Etc/GMT-6" },
  { rails_zone_name: "Karachi", iana_zone_name: "Etc/GMT-5" },
  { rails_zone_name: "Dubai", iana_zone_name: "Etc/GMT-4" },
  { rails_zone_name: "Moscow", iana_zone_name: "Etc/GMT-3" },
  { rails_zone_name: "Jerusalem", iana_zone_name: "Etc/GMT-2" },
  { rails_zone_name: "Amsterdam", iana_zone_name: "Etc/GMT-1" },
  { rails_zone_name: "London", iana_zone_name: "Etc/GMT+0" },
  { rails_zone_name: "Cape Verde Is.", iana_zone_name: "Etc/GMT+1" },
  { rails_zone_name: "Mid-Atlantic", iana_zone_name: "Etc/GMT+2" },
  { rails_zone_name: "Buenos Aires", iana_zone_name: "Etc/GMT+3" },
  { rails_zone_name: "Puerto Rico", iana_zone_name: "Etc/GMT+4" },
  {
    rails_zone_name: "Eastern Time (US & Canada)",
    iana_zone_name: "Etc/GMT+5",
  },
  {
    rails_zone_name: "Central Time (US & Canada)",
    iana_zone_name: "Etc/GMT+6",
  },
  {
    rails_zone_name: "Mountain Time (US & Canada)",
    iana_zone_name: "Etc/GMT+7",
  },
  {
    rails_zone_name: "Pacific Time (US & Canada)",
    iana_zone_name: "Etc/GMT+8",
  },
  { rails_zone_name: "Alaska", iana_zone_name: "Etc/GMT+9" },
  { rails_zone_name: "Hawaii", iana_zone_name: "Etc/GMT+10" },
  { rails_zone_name: "Midway Island", iana_zone_name: "Etc/GMT+11" },
  { rails_zone_name: "Midway Island", iana_zone_name: "Etc/GMT+12" },
];

const ungroupedTimezones = () => {
  return IANA_TIME_ZONES.reduce((allItems, group) => {
    return allItems.concat(group.options);
  }, []);
};

const railsZoneNames = () => {
  return Array.from(new Set(RAILS_ZONE_MAP.mapBy("rails_zone_name")));
};

const getIanaFromRailsZone = (railsZone) => {
  const foundItem = RAILS_ZONE_MAP.find(
    (item) => item.rails_zone_name === railsZone,
  );
  return foundItem ? foundItem.iana_zone_name : null;
};

const getRailsFromIanaZone = (ianaZone) => {
  const foundItem = RAILS_ZONE_MAP.find(
    (item) => item.iana_zone_name === ianaZone,
  );
  return foundItem ? foundItem.rails_zone_name : null;
};
// left off get rails zone from iana

export {
  railsZoneNames,
  ungroupedTimezones,
  getIanaFromRailsZone,
  getRailsFromIanaZone,
  RAILS_ZONE_MAP,
  IANA_TIME_ZONES,
};
