import { defineNuxtPlugin, useRuntimeConfig } from "nuxt/app";
import { createAuth0 } from "@auth0/auth0-vue";

export default defineNuxtPlugin(({ vueApp }) => {
  if (process.client) {
    const config = useRuntimeConfig();
    const authParams = {
      redirect_uri: window.location.origin,
    };
    const auth0 = createAuth0({
      domain: config.public.AUTH0_DOMAIN,
      clientId: config.public.AUTH0_CLIENT_ID,
      cacheLocation: "localstorage",
      authorizationParams: {
        ...authParams,
      },
    });
    vueApp.use(auth0);
  }
});
