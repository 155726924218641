import { useRuntimeConfig } from "nuxt/app";
import { useUserStore } from "@/store/user";
import { useOrgStore } from "@/store/org";

export default defineNuxtRouteMiddleware(() => {
  // Skip middleware on server
  if (import.meta.server) return;

  const config = useRuntimeConfig();
  const intercom = window.Intercom;

  if (config.public.IS_PROD && intercom) {
    // TODO: We need to fetch a bit more data for this.
    // const activeSubscription = orgStore.getOrg?.relationships?.subscriptions?.data?.[0];

    // let subscriptionStatus = null;

    // if (activeSubscription) {

    //   subscriptionStatus = activeSubscription.status;
    // const subscriptionAddons = await activeSubscription
    //   .get("subscriptionAddons")
    //   .mapBy("chargebeeId");
    // subscriptionAddons.forEach((item) => {
    //   let name = null;
    //   switch (item) {
    //     case "social-listening":
    //       name = "SocialListening";
    //       break;
    //     case "verified-authors-usa":
    //       name = "USVCDB";
    //       break;
    //     case "verified-authors-gbr":
    //       name = "GlobalVCDB";
    //       break;
    //     case "global-broadcast-media-monitoring":
    //       name = "GlobalBroadcast";
    //       break;
    //     case "broadcast-monitoring":
    //       name = "USBroadcast";
    //       break;
    //   }
    //   if (item.includes("twitter-amp-")) {
    //     name = "TwitterAmp";
    //   }
    //   if (name) {
    //     intercomOptions[`subscriptionAddon_${name}`] = true;
    //   }
    // });
    // }

    // intercomOptions["subscriptionStatus"] = subscriptionStatus;
    const userStore = useUserStore();
    const orgStore = useOrgStore();

    const intercomOptions = {
      api_base: "https://api-iam.intercom.io",
      app_id: "du7ohuq1",
      name: userStore.getUser?.name,
      user_id: userStore.getUser?.id,
      user_hash: userStore.getUser?.intercom_hash,
      email: userStore.getUser?.email,
      orgName: orgStore.getOrg?.attributes?.name,
      orgId: orgStore.getOrg?.id,
      planName: orgStore.getOrg?.attributes?.plan_name,
      timeZone: orgStore.getOrg?.attributes?.time_zone,
      userSignUpDate: userStore.getUser?.created_at,
      orgSignUpDate: orgStore.getOrg?.attributes?.created_at,
      orgEndDate: orgStore.getOrg?.attributes?.end_date,
      trialAccount: orgStore.getOrg?.attributes?.trial_account,
      digitalLicenses: JSON.parse(
        JSON.stringify(orgStore.getOrg?.attributes?.digital_licenses) || "[]",
      ),
      printLicenses: JSON.parse(
        JSON.stringify(orgStore.getOrg?.attributes?.print_licenses) || "[]",
      ),
      licenseRegion: orgStore.getOrg?.attributes?.license_region || "",
      licenseArticleLimit:
        orgStore.getOrg?.attributes?.licensed_article_limit || "250",
      internalAccount: orgStore.getOrg?.attributes?.internal_account || false,
      subscriptionId:
        orgStore.getOrg?.relationships?.subscriptions?.data?.[0]?.id || null,
      // subscriptionStatus: subscriptionStatus
      // ^ Ember magically gets this one, we should fetch it in the org store
      browserLanguage: navigator.language,
      hide_default_launcher: true,
    };

    intercom("boot", {
      ...intercomOptions,
    });
  } else {
    // eslint-disable-next-line no-console
    console.error("Intercom not available to initialize");
  }
});
