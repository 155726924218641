import { defineStore } from "pinia";
import { useApiCall } from "../composables/useApiCall";
import {
  getExcludedDomainsRequestParams,
  getExcludedMentionsRequestParams,
} from "../utils/exclusionUtils";
import { useSearchStore } from "./search";

export const useExclusion = defineStore("exclusion", {
  state: () => ({
    pubs: [],
    excludedDomains: [],
    mentions: [],
  }),
  actions: {
    setPubs(pubs: any) {
      this.pubs = pubs;
    },
    setMentions(mentions: any) {
      this.mentions = mentions;
    },
    resetPubs() {
      this.pubs = [];
    },
    addExcludedDomain(excludedDomain: any) {
      this.excludedDomains.push(excludedDomain);
    },
    async excludePubs() {
      try {
        const errors = [];

        for (let i = 0; i < this.pubs.length; i++) {
          const pub = this.pubs[i]; // Ensure we're using the correct mention for each iteration
          const { data, error } = await useApiCall(
            "/excluded_domains",
            getExcludedDomainsRequestParams(pub),
          );

          const errCopy = { ...error?.value };
          if (errCopy && errCopy.data) {
            errors.push(errCopy.data.errors[0]);
          }
        }

        const isSuccess = errors.length === 0;

        let errMsg = "manage.mentionsAndPublications.excludePublicationFailure";
        if (
          errors.includes(
            "you_have_reached_the_excluded_domain_limit_for_your_plan",
          )
        ) {
          errMsg =
            "manage.mentionsAndPublications.excludePublicationLimitError";
        }

        if (isSuccess) {
          this.pubs.forEach((pub) => {
            this.addExcludedDomain({
              type: "excluded_domain",
              attributes: {
                media_type: pub.media_type,
                url: pub.media_type === "web" ? pub.url : pub.name,
              },
            });
          });
          return { isSuccess };
        } else {
          return { isSuccess: false, errMsg };
        }
      } catch (e) {
        return {
          isSuccess: false,
          errMsg: "manage.mentionsAndPublications.excludePublicationFailure",
        };
      } finally {
        this.resetPubs();
      }
    },
    async excludeMentions() {
      try {
        const errors = [];
        const errorStatusCodes = [];

        for (let i = 0; i < this.mentions.length; i++) {
          const mention = this.mentions[i]; // Ensure we're using the correct mention for each iteration
          const { error } = await useApiCall(
            "/excluded_articles",
            getExcludedMentionsRequestParams(mention),
          );

          const errCopy = { ...error?.value };
          if (errCopy && errCopy.data) {
            errors.push(errCopy.data.errors[0]);
          }
          if (errCopy && errCopy.statusCode) {
            errorStatusCodes.push(errCopy.statusCode);
          }
        }

        const isSuccess = errorStatusCodes.length === 0;

        let errMsg = "manage.mentionsAndPublications.excludeMentionFailure";
        if (
          errors.findIndex(
            (error) =>
              error.code ===
              "you_have_reached_the_excluded_article_limit_for_your_plan_please_check_your_settings_page_for_current_available_limits",
          ) !== -1
        ) {
          errMsg = "manage.mentionsAndPublications.excludeMentionLimitError";
        }

        return { isSuccess, errMsg };
      } catch (e) {
        return {
          isSuccess: false,
          errMsg: "manage.mentionsAndPublications.excludeMentionFailure",
        };
      } finally {
        this.resetPubs();
      }
    },
    getPubsfromIds(ids: Set<string>) {
      const searchStore = useSearchStore();
      const idArrays = Array.from(ids);

      const filteredMentions = searchStore.mentions.filter((mention) =>
        idArrays.includes(mention.id),
      );

      const uniqueResults = new Set();
      const uniqueArray = [];

      for (const mention of filteredMentions) {
        const item = {
          media_type: mention.media_type,
          url: mention.domain,
          name: mention.publication,
        };
        const key = `${item.media_type}-${item.media_type === "web" ? item.url : item.name}`;

        if (!uniqueResults.has(key)) {
          uniqueResults.add(key);
          uniqueArray.push(item);
        }
      }

      return uniqueArray;
    },
    getMentionsfromIds(ids: Set<string>) {
      const searchStore = useSearchStore();
      const idArrays = Array.from(ids);
      const mentionArray = [];

      const filteredMentions = searchStore.mentions.filter((mention) =>
        idArrays.includes(mention.id),
      );
      for (const mention of filteredMentions) {
        let mentionTitle = mention.title;
        if (
          mention.title === "" &&
          (mention.media_type === "tv" || mention.media_type === "radio")
        ) {
          mentionTitle =
            mention.metadata?.broadcast?.program_name ||
            "No Program Name Available";
        }
        const item = {
          author: mention.author,
          country: mention.country,
          media_type: mention.media_type,
          publication: mention.publication,
          published_on: mention.published_on,
          title: mentionTitle,
          url: mention.url,
        };

        mentionArray.push(item);
      }

      return mentionArray;
    },
  },
});
