import { computed } from "vue";
import { ElNotification } from "element-plus";
import { useNuxtApp } from "nuxt/app";
import { EmitNotificationOptions } from "~/types/EmitNotificationOptions";

export const useEmitNotification = ({
  showSuccessMessage,
  successMessage,
  errorMessage,
  translateMessage = false,
  translationOptions = {},
}: EmitNotificationOptions): void => {
  const nuxtApp = useNuxtApp();

  const messageToDisplay = computed(() => {
    const message = showSuccessMessage ? successMessage : errorMessage;

    return translateMessage
      ? nuxtApp.vueApp.__VUE_I18N__.global.t(message, translationOptions)
      : message;
  });

  ElNotification({
    customClass: showSuccessMessage
      ? "notification-popup success"
      : "notification-popup error",
    dangerouslyUseHTMLString: true,
    message: messageToDisplay.value,
    duration: 5000,
    showClose: true,
    position: "bottom-left",
    offset: 50,
  });
};
