import { Context, Middleware } from "@nuxt/types";
import { abortNavigation, navigateTo } from "#app";
import { useAuth0 } from "@auth0/auth0-vue";
import { useJwtStore } from "../store/jwt";
import { setSessionData } from "~/utils/SetSessionData";
import { IsUnauthenticatedRoute } from "~/utils/IsUnauthenticatedRoute";
import { useUserStore } from "@/store/user";
import { useOrgStore } from "@/store/org";
import { until } from "@/utils/Async";

const authMiddleware: Middleware = async (context: Context) => {
  if (import.meta.client) {
    try {
      // Don't check authentication for unprotected routes
      if (IsUnauthenticatedRoute(context.path)) {
        return;
      }

      const auth0 = useAuth0();
      if (auth0.isLoading.value) {
        await until(() => !auth0.isLoading.value);
      }

      await auth0.checkSession();
      const isAuthenticated = auth0.isAuthenticated.value;

      if (!isAuthenticated) {
        await auth0.loginWithRedirect();
        return abortNavigation();
      } else {
        const jwt = await auth0.getAccessTokenSilently();
        const jwtStore = useJwtStore();
        jwtStore.updateSessionJwt(jwt);
        // always set localstorage user/org data
        const response = await setSessionData();
        if (response === "NotAuthorized") {
          // redirect to org expired logout
          if (context.fullPath !== "/expired") {
            await navigateTo("/expired", {
              external: true,
            });
          }
        } else if (context.fullPath === "/") {
          const path = `/search`;
          await navigateTo(path, {
            external: true,
          });
        }
      }
      const userStore = useUserStore();
      userStore.setUser(JSON.parse(localStorage.getItem("user") ?? "{}"));
      const orgStore = useOrgStore();
      orgStore.setOrg(JSON.parse(localStorage.getItem("organization") ?? "{}"));
    } catch (error) {
      console.error("Error in authMiddleware", error);
      // redirect to login page
      await navigateTo("/logout", {
        external: true,
      });
    }
  }
};

export default authMiddleware;
