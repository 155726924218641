/*
  Await a condition. Use sparingly, it's expensive
  and WILL break things if the condition is never met.
  Usage example:
  await until(() => myBankAccount > 10000000)
*/
export function until(conditionFunction: Function) {
  const poll = (resolve: Function) => {
    if (conditionFunction()) resolve();
    else setTimeout(() => poll(resolve), 100);
  };
  return new Promise(poll);
}
