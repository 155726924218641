import { defineStore } from "pinia";
import JwtType from "~/types/JwtType";

export const useJwtStore = defineStore("sessionJwt", {
  state: (): JwtType => ({
    sessionJwt: null,
  }),
  actions: {
    updateSessionJwt(token: string) {
      this.sessionJwt = token;
      localStorage.setItem("auth_token", token);
    },
    clearSessionJwt() {
      this.sessionJwt = null;
      localStorage.removeItem("auth_token");
    },
  },
});
