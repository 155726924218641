export function hasNonEmptyAttribute(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== "") {
      // Return true if any attribute is not an empty string
      return true;
    }
  }
  // Return false if all attributes are empty strings
  return false;
}
