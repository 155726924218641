import OrgType from "~/types/OrgType";

export function checkPlan(org: string, translationKey: string) {
  const parsedOrg = JSON.parse(org || "{}");

  switch (translationKey) {
    case "navbar.monitor.search": // Search
    case "common.mentions": // Mentions
    case "navbar.manage.targetMediaLists": // Target Lists
    case "navbar.monitor.chartAnalytics": // Chart Analytics
    case "common.topics": // Topics
    case "common.reports": // Reports
    case "navbar.contacts.searchContacts": // Search Contacts
    case "navbar.contacts.emailCampaigns": // Email Campaigns
    case "navbar.manage.monitorSearch": // Monitor Search
    case "navbar.manage.emailDigests": // Email Digests
    case "navbar.manage.manageDashboard": // Manage Dashboard
      if (hasBaseMonitoring(parsedOrg)) {
        return true;
      }
      break;
    case "navbar.monitor.authorsAndPublications": // Authors” & Publications
      // TODO: only old users should have access
      if (hasBaseMonitoring(parsedOrg) && hasAuthorIntelligence(parsedOrg)) {
        return true;
      }
      break;
    case "navbar.contacts.myContacts": // My List
    case "navbar.contacts.pitchLists": // Pitch List
      if (hasBaseMonitoring(parsedOrg) || hasVCDB(parsedOrg)) {
        return true;
      }
      break;
    case "common.alerts": // Alerts
      if (hasBaseMonitoring(parsedOrg) && hasEmailAlerts(parsedOrg)) {
        return true;
      }
      break;
  }

  return false;
}

function hasVCDB(org: OrgType) {
  return org?.attributes?.is_vcdb_only;
}

function hasBaseMonitoring(org: OrgType) {
  return org?.attributes?.plan_features.baseMonitoring;
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
function hasSocialTracking(org: OrgType) {
  return org?.attributes?.plan_features.socialTracking;
}

function hasAuthorIntelligence(org: OrgType) {
  return org?.attributes?.plan_features.authorIntelligence;
}

function hasEmailAlerts(org: OrgType) {
  return org?.attributes?.plan_features.emailAlerts;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
function hasTwitterAmp(org: OrgType) {
  return org?.attributes?.plan_features.twitterAmp;
}

//   function currentSearchDashboard(org: JSON) {
//     return this.session.activeSearchDashboard.get("id");
//   }

//   function currentProfile(org: JSON) {
//     return this.session.currentUser.currentProfile.get("id");
//   }
